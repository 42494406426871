import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UserModel} from '../models';
import {Router} from '@angular/router';
import {LoginService} from '../services/login.service';

declare function clearNav(): any;

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.css']
})
export class MyBookingsComponent implements OnInit, OnDestroy {

  viewModeTab = 'tab-1';

  subscriptionLogin: Subscription;
  userLogged: UserModel;

  constructor(
    private _router: Router,
    private _loginService: LoginService) {
  }

  ngOnInit() {
    //
    clearNav();

    this.subscriptionLogin = new Subscription();
    this.userLogged = this._loginService.getUserInfo();

    this.listenLogin();

    if (this.userLogged === null) {
      this._router.navigate(['/home']).then();
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionLogin) {
      this.subscriptionLogin.unsubscribe();
    }
  }

  listenLogin() {
    this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
      (data) => {
        switch (data) {
          case null:
          case undefined:
            this.userLogged = null;
            this._router.navigate(['/home']).then();
            break;
          default:
            this.userLogged = data;
            break;
        }
      })
    );
  }

  onChangeModeTab(vmTxt) {
    //
    this.viewModeTab = vmTxt;
  }

}
