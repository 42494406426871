import { Component, OnInit } from '@angular/core';

declare function closeWindow(event: any, wID: any);

@Component({
  selector: 'app-w-under-construction-y',
  templateUrl: './w-under-construction-y.component.html',
  styleUrls: ['./w-under-construction-y.component.css']
})
export class WUnderConstructionYComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onCloseWindow(event, wID) {
    event.stopPropagation();
    //
    closeWindow(event, wID);
  }

}
