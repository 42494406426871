import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ReservationResultModel} from '../../models/reservation-result.model';
import {UserModel} from '../../models';
import {HotelService} from '../../services/hotel.service';
import {PrintService} from '../../services/print-service.service';
import {LoginService} from '../../services/login.service';
import {Subscription} from 'rxjs';

declare function clearNav(): any;

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.css']
})
export class PaymentConfirmationComponent implements OnInit, OnDestroy {

  subscriptionLogin: Subscription;

  reservationResult: ReservationResultModel;
  userLogged: UserModel;

  constructor(
    private _hotelService: HotelService,
    private _router: Router,
    private _loginService: LoginService
  ) {
  }

  ngOnInit() {
    //
    this.subscriptionLogin = new Subscription();
    this.userLogged = this._loginService.getUserInfo();

    this.checkingData();
    this.listenLogin();
  }

  ngOnDestroy() {
    this._hotelService.resetReservation();
  }

  listenLogin() {
    this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
      (data) => {
        switch (data) {
          case null:
          case undefined:
            this.userLogged = null;
            break;
          default:
            this.userLogged = data;
            break;
        }
      })
    );
  }

  checkingData() {
    this.reservationResult = HotelService.getReservationResult();

    switch (this.reservationResult) {
      case null:
      case undefined:
        HotelService.resetReservationResult();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

  }

  onDownloadPdf() {
    this._hotelService.resetReservation();
    window.open(this.reservationResult.url);
  }

  onGo(url) {
    //
    this._router.navigate(['/' + url]).then();
  }
}
