import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HotelModel, ReservationModel, SearchModel} from '../../models';
import {Subscription} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {HotelService} from '../../services/hotel.service';
import {GeneralUtil} from '../../core/util/general.util';
import Swal from 'sweetalert2';
import {RequestParams} from '../../core/api/api.model';

declare function clearNav(): any;
declare function openWindow(event: any, wID: any);

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.css']
})
export class HotelsComponent implements OnInit, OnDestroy {

  reservation: ReservationModel;
  search: SearchModel = null;
  hotels: HotelModel[];
  subscriptionObject: Subscription;
  subscriptionHotelDetailsData: Subscription;
  message = 'Searching';

  constructor(
    private _router: Router,
    private _spinner: NgxSpinnerService,
    private _hotelService: HotelService
  ) {
  }

  ngOnInit() {
    clearNav();
    document.getElementById('mm1').classList.add('active');
    this.subscriptionObject = new Subscription();
    this.subscriptionHotelDetailsData = new Subscription();

    this.checkingData();
    this.listenSearch();
  }

  ngOnDestroy(): void {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

  checkingData() {
    this.reservation = this._hotelService.getReservation();

    switch (this.reservation) {
      case null:
      case undefined:
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        if (!GeneralUtil.isListHotelsAlive(this.reservation.checkVit)) {
          this.searchExpireMessage();
          this._router.navigate(['/hotel.search']).then();
          return;
        }
        this.hotels = this.reservation.list_hotels;
        this.search = this.reservation ? this.reservation.search : null;
        if (!this.search) {
          this.reservation.offer = null;
          this.reservation.hotel = null;
          this._hotelService.setReservation(this.reservation);
          this._router.navigate(['/hotel.search']).then();
          return;
        }
        break;
    }
  }

  thumbnailCheck(url) {
    const noImg = '../../../assets/images/404/nopic-en.png';
    this.checkImage(url, function (exists) {
      if (exists) {
        return url;
      } else {
        return noImg;
      }
    });
  }

  checkImage(url, callback) {
    const image = new Image();
    image.src = url;
    image.onerror = function () {
      callback(false);
    };
    image.onload = function () {
      callback(true);
    };
  }

  listenSearch() {
    this.subscriptionObject = this._hotelService._fireHotelSearch.subscribe(
      () => this.ngOnInit()
    );
  }

  searchExpireMessage() {
    Swal.fire({
      title: 'Expired search',
      text: 'Your search has an expiration time of 20 min, these have already passed. Try again',
      type: 'warning',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'New Search'
    }).then((result) => {
      if (result.value) {
        const oldReservation = this._hotelService.getReservation();
        oldReservation.automatic_refresh = true;
        this._hotelService.setReservation(oldReservation);
        this._router.navigate(['/hotel.search']).then();
      }
    });
    // A la fuerza
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

  // Links To

  onRooms(event, HotelID) {
    //
    const reservation = this._hotelService.getReservation();
    //
    if (!this.hotels[HotelID].details_data.description) {
      const params: RequestParams = {
        params: {
          id: this.hotels[HotelID].hotel_code
        },
        search: {
          id_search_code: this.hotels[HotelID].offers[0].hotel_search_code
        }
      };
      this._spinner.show().then();
      this.subscriptionHotelDetailsData.add(this._hotelService.getHotelDetails(params).subscribe(
        (data) => {
          this._spinner.hide().then();
          this.hotels[HotelID].details_data = data.data;
          reservation.hotel = this.hotels[HotelID];
          reservation.search = this.search;
          reservation.offer = this.hotels[HotelID].offers[0];
          reservation.offerIndex = 0;
          this._hotelService.setReservation(reservation);
          this._router.navigate(['/hotel']).then();

        }
      ));
    } else {
      reservation.hotel = this.hotels[HotelID];
      reservation.search = this.search;
      reservation.offer = this.hotels[HotelID].offers[0];
      reservation.offerIndex = 0;
      this._hotelService.setReservation(reservation);
      this._router.navigate(['/hotel']).then();
    }
  }

  onBook(event, HotelID, OfferID: number = 0) {
    event.stopPropagation();
    //
    // ir a Tab 1

    const reservation = this._hotelService.getReservation();
    reservation.hotel = this.hotels[HotelID];
    reservation.offer = this.hotels[HotelID].offers[OfferID];
    reservation.search = this.search;
    reservation.use_qroo_info = null;
    reservation.guests = [];
    reservation.book_info = null;

    this._hotelService._currOffer = this.hotels[HotelID].offers[OfferID];

    this._hotelService.setReservation(reservation);
    this._router.navigate(['/hotel.booking']).then();
  }

}
