import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralUtil} from '../../core/util/general.util';
import {SignUserModel, UserModel} from '../../models';
import {LoginService} from '../../services/login.service';
import {UserService} from '../../services/user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subscription} from 'rxjs';

declare function closeWindow(event: any, wID: any);

declare function closeWindowWithoutEvent(wID: any);


@Component({
  selector: 'app-w-login',
  templateUrl: './w-login.component.html',
  styleUrls: ['./w-login.component.css']
})
export class WLoginComponent implements OnInit {

  signForm: FormGroup;
  subscriptionLogin: Subscription;
  userLogged: UserModel;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _loginService: LoginService,
    private _spinner: NgxSpinnerService,
    private _userService: UserService) {
  }


  ngOnInit() {

    this.fillForm();
    this.userLogged = this._loginService.getUserInfo();

    if (this.userLogged) {
      closeWindowWithoutEvent('w-login');
    }

    this.listenLogin();
  }

  listenLogin() {
    this.subscriptionLogin = new Subscription();
    this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
      (data) => {
        switch (data) {
          case null:
          case undefined:
            this.userLogged = null;
            break;
          default:
            this.userLogged = data;
            break;
        }
        if (this.userLogged) {
          closeWindowWithoutEvent('w-login');
        }
      })
    );
  }

  onSubmit() {
    if (this.signForm.valid) {
      const signUpUser = new SignUserModel();
      signUpUser.email = this.signForm.value.email;
      signUpUser.password = this.signForm.value.password;
      this._spinner.show().then();
      this._loginService.doLogin(signUpUser).subscribe(
        (data) => {
          this._spinner.hide().then();
          GeneralUtil.successMessage('Login Successful!');
          this.getLoggedUser();
        }, (err) => {
          this._spinner.hide().then();
          GeneralUtil.errorMessage(err.error.message);
        }
      );
    } else {
      GeneralUtil.validateAllFormFields(this.signForm);
    }
  }

  getLoggedUser() {
    this._loginService.getUserDetails().subscribe(
      (data) => {
        this._loginService.fireLoginChange(data.data);
        closeWindowWithoutEvent('w-login');
      });

  }

  fillForm() {
    this.signForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, Validators.required)
    });
  }


  onCloseWindow(event, wID) {
    if (event) {
      event.stopPropagation();
    }
    //
    closeWindow(event, wID);
  }

  onLogIn(event, wID) {
    event.stopPropagation();
    //
    closeWindow(event, wID);
  }

  onRegister(event, wID) {
    event.stopPropagation();
    //
    closeWindow(event, wID);
    this._router.navigate(['/register']).then();
  }

}
