import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of, Subject} from 'rxjs';
import {map, mapTo, share, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {apiRoutes} from '../api-routing';
import {StoreUtil} from '../core/util/store.util';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SignUserModel, UserModel} from '../models';


/**
 * @service AuthenticatedUserService - Servicio para autenticar Operadores
 */

@Injectable()
export class LoginService {
  redirectUrl: string;
  refresh: Observable<any>;
  _logged: Subject<any> = new Subject<any>();
  _fireOLoginChange: Subject<any> = new Subject<any>();
  private _baseUrl = environment.apiUrl;
  private _userLogin: UserModel;

  constructor(
    private _http: HttpClient,
    private _router: Router
  ) {
  }

  doLogin(singUser: SignUserModel) {
    const options = {
      email: singUser.email,
      password: singUser.password
    };
    return this._http
      .post(this._baseUrl + apiRoutes.r_user_auth_login, options, {}).pipe(
        map((data: Response) => {
          fixToken(data);
          this._logged.next(data);
          return data;
        })
      )
      ;
  }

  doLogout() {
    const headers = signRequest(new HttpHeaders());
    return this._http
      .get(this._baseUrl + apiRoutes.r_user_auth_logout, {headers}).pipe(
        map((response: Response) => {
          StoreUtil.remove('token');
          StoreUtil.remove('user');
          this._logged.next(false);
          return response;
        })
      );
  }

  fireLoginChange(data) {
    this._fireOLoginChange.next(data);
  }

  getUserDetails() {
    const headers = signRequest(new HttpHeaders());
    return this._http
      .get(this._baseUrl + apiRoutes.r_user_auth_logged_user, {headers}).pipe(
        map((response: any) => {
          const data = response;
          StoreUtil.objectToStore('user', data.data);
          return response;
        })
      );
  }

  doRefresh(force = false): Observable<any> {
    const token = stToken();
    if (!token) {
      return of(false);
    }

    if (this.refresh) {
      return this.refresh;
    }

    const options = {refresh_token: token.refresh_token};
    this.refresh = this._http
      .post(this._baseUrl + apiRoutes.r_user_auth_login_refresh, options, {headers: contentHeaders}).pipe(
        tap(response => fixToken(response)),
        tap(() => (this.refresh = null)),
        mapTo(true),
        share()
      );


    return this.refresh;
  }

  getToken(): any {
    return stToken();
  }

  getUserInfo(): any {
    return StoreUtil.objectFromStore('user');
  }
}

function fixToken(token): any {
  return stToken({millis: new Date().getTime(), ...token});
}

function stToken(newToken = null): any {
  if (newToken) {
    StoreUtil.objectToStore('token', newToken);
  }

  return StoreUtil.objectFromStore('token');
}

export const contentHeaders = new HttpHeaders();
contentHeaders.append('Accept', 'application/json');
contentHeaders.append('Content-Type', 'application/json');

export const signRequest = (headers: HttpHeaders): HttpHeaders => {
  const token = stToken();

  if (!token) {
    return headers;
  }

  headers.append('Authorization', 'Bearer ' + token.access_token);
  return headers;
};
