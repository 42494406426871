import {Injectable} from '@angular/core';
import {ApiService} from '../core/api/api.service';
import {RequestParams} from '../core/api/api.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {apiRoutes} from '../api-routing';
import {UserModel} from '../models';


@Injectable()
export class UserService {

  /*   _incomplit: Subject<string> = new Subject<string>();
    _refreshAdminView: Subject<any> = new Subject<any>(); */

  constructor(
    private _apiService: ApiService
  ) {
  }

  register(user: UserModel) {
    return this._apiService.post(apiRoutes.r_user_users_register, user).pipe(
      map(
        (data) => {
          return data;
        }
      ));
  }

  registerGuest(user) {
    return this._apiService.post(apiRoutes.r_user_users_register_guest, user).pipe(
      map(
        (data) => {
          return data;
        }
      ));
  }

  confirmAccount(code: string = ''): Observable<any> {
    const finalPath = apiRoutes.r_user_users_activate_account + '?code=' + code;
    return this._apiService.getSimple(finalPath);
  }

  /* updateOldOneCreateNew(supply: SupplyModel) {
    return this._apiService.post(apiRoutes.r_supplies_close_old_one_open_new, supply).map(
      (data) => {
        return data;
      }
    );
  } */


  /* changeStatus(supply: SupplyModel, newStatus) {
    var params: RequestParams = {
      params: {
        id: supply.id
      }
    }
    var body: RequestBody = {
      status: newStatus
    }

    return this._apiService.patch(apiRoutes.r_supplies_change_status, body, params).map(
      (data) => {
        return data;
      });
  } */


}
