import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

import {RequestBody, RequestFilter, RequestParams} from './api.model';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';


@Injectable()
export class ApiService {
  private _baseUrl = environment.apiUrl;

  constructor(private _http: HttpClient, private _router: Router) {
  }

  get(path: string, params: RequestParams = {}): Observable<any> {
    const options: {} = this.resolveRequestOptions(params);
    return this._http
      .get(`${this._baseUrl}${this.resolveUrlParams(path, params.params)}`, options);
  }

  getLimit(path: string, params: RequestParams = {}): Observable<any> {
    const options: {} = this.resolveRequestOptions(params);
    return this._http
      .get(`${this._baseUrl}${this.resolveCustomUrlHotelSearch(path, params)}`);
  }

  getSimple(path: string): Observable<any> {
    return this._http
      .get(`${this._baseUrl}${path}`);
  }

  getHotelDesktopList(path: string, params: RequestParams = {}): Observable<any> {
    const url = String(this._baseUrl + this.resolveRequestOptionsKeys(path, params.params));
    return this._http.get(url);
  }

  post(path: string, body: RequestBody = {}, params: RequestParams = {}): Observable<any> {
    return this._http
      .post(`${this._baseUrl}${this.resolveUrlParams(path, params.params)}`, body);
  }

  put(path: string, body: RequestBody = {}, params: RequestParams = {}): Observable<any> {
    return this._http
      .put(`${this._baseUrl}${this.resolveUrlParams(path, params.params)}`, body);
  }

  patch(path: string, body: RequestBody = {}, params: RequestParams = {}): Observable<any> {
    return this._http
      .patch(`${this._baseUrl}${this.resolveUrlParams(path, params.params)}`, body);
  }

  delete(path: string, params: RequestParams = {}): Observable<any> {

    return this._http
      .delete(`${this._baseUrl}${this.resolveUrlParams(path, params.params)}`);
  }

  public resolveUrlParams(url: string, urlParams: { [key: string]: any }) {
    if (!urlParams) {
      return url;
    }

    return Object.keys(urlParams).reduce(
      (_url, key) => _url.replace(new RegExp('{' + key + '}', 'g'), urlParams[key]),
      url
    );
  }

  public resolveRequestOptions(requestParams: RequestParams): any {
    const options: any = {};

    let params = requestParams.search;

    if (requestParams.filters) {
      const filters = requestParams.filters.reduce(
        (filtersList, filter: RequestFilter) => [...filtersList, [filter.field, filter.op, filter.data]],
        []
      );

      params = {...params, filter: JSON.stringify(filters)};
    }

    return {params};
  }

  public resolveRequestOptionsKeys(url, urlParams): any {
    if (!urlParams) {
      return url;
    }

    const paramURL = '?filter=' + JSON.stringify(urlParams.filter);

    return url + paramURL;
  }

  resolveCustomUrlHotelSearch(url, params) {
    let paramURL = '?filter=[';

    console.log(params);

    for (const filter of params.filters) {
      if (filter.field === 'rooms_persons') {
        paramURL += '["' + filter.field + '"';
        paramURL += ',"' + filter.op + '"';
        paramURL += ',' + JSON.stringify(filter.data) + '';
        paramURL += '],';
      } else if (filter.field === 'rooms_number') {
        paramURL += '["' + filter.field + '"';
        paramURL += ',"' + filter.op + '"';
        paramURL += ',' + filter.data + '';
        paramURL += '],';
      } else {
        paramURL += '["' + filter.field + '"';
        paramURL += ',"' + filter.op + '"';
        paramURL += ',"' + filter.data + '"';
        paramURL += '],';
      }
    }

    let completeURL = url + paramURL;

    completeURL = completeURL.substring(0, completeURL.length - 1) + ']';

    completeURL += '&amount=' + 100;
    completeURL += '&origin=' + 'desktop.qroo.com';

    return completeURL;
  }


}
