import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {HotelService} from '../../services/hotel.service';
import {PrintService} from '../../services/print-service.service';
import {Subscription} from 'rxjs';
import {BookingModel} from '../../models/booking.model';
import {UserModel} from '../../models';
import {Router} from '@angular/router';
import {LoginService} from '../../services/login.service';
import {GeneralUtil} from '../../core/util/general.util';

@Component({
  selector: 'app-tab-hotels',
  templateUrl: './tab-hotels.component.html',
  styleUrls: ['./tab-hotels.component.css']
})
export class TabHotelsComponent implements OnInit, OnDestroy {

  bookings: BookingModel[];
  subscriptionObject: Subscription = new Subscription();

  subscriptionLogin: Subscription;
  userLogged: UserModel;

  constructor(
    private _hotelService: HotelService,
    private _spinner: NgxSpinnerService,
    private _router: Router,
    private _loginService: LoginService,
    private _printService: PrintService
  ) {
  }

  ngOnInit() {
    this.subscriptionLogin = new Subscription();
    this.userLogged = this._loginService.getUserInfo();

    this.listenLogin();

    if (this.userLogged === null) {
      this._router.navigate(['/home']).then( () => window.location.reload());
      return;
    }

    this.getReservations();
  }

  ngOnDestroy(): void {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
    if (this.subscriptionLogin) {
      this.subscriptionLogin.unsubscribe();
    }
  }

  listenLogin() {
    this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
      (data) => {
        switch (data) {
          case null:
          case undefined:
            this.userLogged = null;
            this._router.navigate(['/home']).then();
            break;
          default:
            this.userLogged = data;
            break;
        }
      })
    );
  }

  getReservations() {
    this._spinner.show().then();
    this.subscriptionObject.add(this._hotelService.getReservations().subscribe(
      (data) => {
        this._spinner.hide().then();
        this.bookings = data.data.reverse();
      }, (data) => {
        this._spinner.hide().then();
        GeneralUtil.errorMessage(data.error.message);
      }
    ));
  }

  onDownloadPdf(url) {
    window.open(url);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON DOWNLOAD ()
  onDownload(){
    //
  }

}
