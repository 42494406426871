import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HotelDetailModel, HotelInfoModel, HotelModel, OfferModel, ReservationModel, SearchModel} from '../../models';
import {Subscription} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {HotelService} from '../../services/hotel.service';
import {RequestParams} from '../../core/api/api.model';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {GeneralUtil} from '../../core/util/general.util';
import Swal from 'sweetalert2';
import {QuoteModel} from '../../models/quote.model';
import * as _ from 'lodash';

declare function clearNav(): any;

declare function toggleSlider(event: any);

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.css']
})
export class HotelComponent implements OnInit, OnDestroy {

  subscriptionObject: Subscription;
  subscriptionQuoteData: Subscription;
  hotelData: HotelInfoModel;
  viewModeGalMap = 'gal';

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];


  search: SearchModel;
  hotel: HotelModel;
  reservation: ReservationModel;
  currentOffer: OfferModel = null;
  checkVit: boolean = true;

  quoteData: QuoteModel;

  quoteID: string;
  offerID: string;

  display: boolean = false;

  offerIndex: number;

  constructor(
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _hotelService: HotelService,
    private _routeHandler: ActivatedRoute
  ) {
  }

  ngOnInit() {
    clearNav();
    //
    document.getElementById('mm1').classList.add('active');

    this.galleryOptions = [
      {
        imageSize: 'contain',
        width: '100%',
        height: '23rem',
        imageInfinityMove: true,
        thumbnailsColumns: 5,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.reservation = this._hotelService.getReservation();

    this.checkingData();

    this.subscriptionObject = new Subscription();

    if (this.hotel.details_data === null || !this.hotel.details_data) {
      this.getHotelDetails();
    }

    if (this.hotel.details_data !== null || this.hotel.details_data) {
      this.populateGallery();
    }

    this.display = true;

    this.triggerChange(this.reservation.hotel.offers[this.reservation.offerIndex], true, this.reservation.offerIndex);
  }

  ngOnDestroy(): void {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
    if (this.subscriptionQuoteData) {
      this.subscriptionQuoteData.unsubscribe();
    }
  }

  checkingData() {
    switch (this.reservation) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        if (!GeneralUtil.isListHotelsAlive(this.reservation.checkVit)) {
          this.searchExpireMessage();
          this._router.navigate(['/hotel.search']).then();
          return;
        }
        break;
    }

    this.search = this.reservation.search;

    switch (this.search) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.hotel = this.reservation.hotel;

    switch (this.hotel) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

  }

  populateGallery() {
    const tempGalleryImages = [];
    const tempPictArray = this.hotel.details_data.pictures;
    const noImg = '../../../assets/images/404/nopic-en.png';

    for (let i = 0; i < tempPictArray.length; i++) {
      this.checkImage(tempPictArray[i].url, function (exists) {
          if (exists) {
            tempGalleryImages.push(
              {
                small: tempPictArray[i].url,
                medium: tempPictArray[i].url,
                big: tempPictArray[i].url
              }
            );
          }
        }
      );
    }

    if (tempPictArray.length === 0) {
      tempGalleryImages.push(
        {
          small: noImg,
          medium: noImg,
          big: noImg
        }
      );
    }
    this.galleryImages = tempGalleryImages;
  }

  checkImage(url, callback) {
    const image = new Image();
    image.src = url;
    image.onerror = function () {
      callback(false);
    };
    image.onload = function () {
      callback(true);
    };
  }

  getHotelDetails() {
    const params: RequestParams = {

      params: {
        id: this.hotel.id
      },
      search: {
        id_search_code: this.hotel.offers[0].hotel_search_code
      }
    };
    this.spinner.show().then();
    this._hotelService.getHotelDetails(params).subscribe(
      (data) => {
        this.spinner.hide().then();
        this.hotel.details_data = data.data;
        this.populateGallery();
      }, (error) => {
        this.spinner.hide().then();
        if (error.name && error.name === 'TimeoutError') {
          Swal.fire({
            html: '<h3 class="title text-uc text-c4 tac mt-1 mb-2">A time out error occurred</h3>' +
              '<p class="mb-1">The provider is temporarily out of service.</p><p class="mb-2">Repeat your search or start a new one.</p>',
            type: 'error',
            showCancelButton: true,
            cancelButtonText: 'NEW SEARCH',
            confirmButtonText: 'REPEAT SEARCH',
            customClass: {
              confirmButton: 'btn btn-sm btn-3rd col-45-1 px-0',
              cancelButton: 'btn btn-sm btn-2nd col-45-1 px-0',
              actions: 'full-w'
            }
          }).then((result) => {
            if (result.value) {
              this._router.navigate(['/hotels']).then();
            }
          });
        }
      }
    );
  }

  triggerChange(offer, init: boolean = false, index: number = 0) {
    if (init) {
      this._hotelService.fireOfferChange(offer);
      this.currentOffer = offer;
      this.offerIndex = index;
    } else {
      if (offer !== this.currentOffer) {
        this._hotelService.fireOfferChange(offer);
        this.currentOffer = offer;
        this.offerIndex = index;
      }
    }
  }

  checkHotelFacilitiesArray(data) {
    return Array.isArray(data);
  }

  fixHtmlString(str) {
    const newStr = str.split('</br>');
    newStr.splice(-1, 1);
    return newStr;
  }

  onSldr($event) {
    //
    toggleSlider(event);
    this.viewModeGalMap = this.viewModeGalMap === 'gal' ? 'map' : 'gal';
  }

  googleAPIMapLink() {
    let mapUrl = 'https://www.google.com/maps/embed/v1/place?q=';
    mapUrl += this.hotel.details_data.latitude + ',' + this.hotel.details_data.longitude;
    mapUrl += '&key=';
    // noinspection SpellCheckingInspection
    mapUrl += 'AIzaSyDjL19EuV-7T1PQc9IV_a1M6Y2jFvkzMSE&maptype=satellite';
    return mapUrl;
  }

  onBook(event) {
    event.stopPropagation();
    //
    if (this.currentOffer === null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Please select an offer'
      }).then();
      return;
    }
    const reservation = this._hotelService.getReservation();
    reservation.hotel = this.hotel;
    reservation.offer = this.currentOffer;
    reservation.search = this.search;
    reservation.use_qroo_info = null;
    reservation.guests = [];
    reservation.book_info = null;
    reservation.offerIndex = this.offerIndex;

    // this._hotelService._currOffer = this.hotels[HotelID].offers[OfferID];

    this._hotelService.setReservation(reservation);
    this._router.navigate(['/hotel.booking']).then();
  }

  onBack() {
    if (this.reservation.featuredHotel) {
      this._hotelService.resetReservation();
      this._router.navigate(['/' + this.reservation.previousPage]).then();
    } else {
      this._router.navigate(['/hotels']).then();
    }
  }

  // noinspection JSUnusedGlobalSymbols
  searchExpireMessage() {
    Swal.fire({
      title: 'Expired search',
      text: 'Your search has an expiration time of 20 min, these have already passed. Try again',
      type: 'error',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'New Search'
    }).then((result) => {
      if (result.value) {
        this._router.navigate(['/hotel.search']).then();
      }
    });
    // Forced
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

}
