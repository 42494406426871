import {HotelModel, OfferModel, SearchModel, UserModel} from '.';

export class ReservationModel {
  search: SearchModel;
  hotel: HotelModel;
  offer: OfferModel;
  book_info: UserModel;
  guests: GuestModel[];
  coming_from_search: boolean;
  list_hotels: HotelModel[];
  actual_time: number;
  automatic_refresh: boolean;
  use_qroo_info: any;
  featuredHotel: boolean;
  previousPage: string;
  checkVit: boolean = true;
  offerIndex: number;
  booking_valuation: boolean = false;
}

export class GuestModel {
  room_type: string;
  room_number: number;
  adults: { title: string, name: string, last_name: string }[];
  childrens: { title: string, name: string, last_name: string, age: number }[];
  rate_key: string = null;
  base_price: string = '';
  total_price: string = '';
}


