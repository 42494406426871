import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {Router} from '@angular/router';
import {NauticalModel, NauticalReservationModel} from '../../models/nautical.model';
import {NauticalService} from '../../services/nautical.service';

declare function clearNav(): any;

declare function setImageContainers(containers: any);

declare function goLeft(event: any, tElem: any);

declare function goRight(event: any, tElem: any);

declare function openWindow(event: any, wID: any);

@Component({
  selector: 'app-nautical',
  templateUrl: './nautical.component.html',
  styleUrls: ['./nautical.component.css']
})
export class NauticalComponent implements OnInit, AfterViewInit, OnDestroy {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  packagesList: NauticalModel[];
  limit = 0;

  // - - - - - - - - - - - - - - - - - - - GALLERY DESCRIPTIONS
  gc1 = '<div class="gallery-text-container"><div class="px-15 py-1 i-name"><div class="hg-title ff-con">';
  gc2 = '</div><div class="hg-subtitle ff-con">';
  gc3 = '</div></div><div class="hg-city px-15 py-05 ff-rnd">';
  gc4 = '</div><div class="hg-price px-15 py-1 tar"><div class="fr tac"><span class="curr ff-con">$</span><span class="price ff-rnd">';
  gc5 = '</span></div><div class="fr tac"><span class="line1 ff-con block">&nbsp;</span><span class="line2 ff-con block text-w">';
  gc6 = '</span></div><div class="cb"></div></div></div>';


  constructor(
    private _router: Router,
    private _nautService: NauticalService
  ) {
  }

  ngOnInit() {

    this.getPackages().then( () => {
      setImageContainers('scroll-container');
    });
    //
    clearNav();
    document.getElementById('mm4').classList.add('active');
    //
    this.galleryOptions = [
      {
        width: '100%',
        height: '100%',
        preview: false,
        // fullWidth: true,
        imageArrows: false,
        imageAutoPlay: true,
        thumbnails: false,
        imageDescription: true,
        imageAutoPlayPauseOnHover: true,
        imageAutoPlayInterval: 7000,
        imageAnimation: NgxGalleryAnimation.Slide,
      }
    ];

    this.galleryImages = [
      {
        small: '../../assets/images/banner/yacht-1.jpg',
        medium: '../../assets/images/banner/yacht-1.jpg',
        big: '../../assets/images/banner/yacht-1.jpg',
        description: this.gc1 + 'Isla Mujeres VIP' +
          this.gc2 + 'Yacht Tour' +
          this.gc3 + 'Cancun' +
          this.gc4 + '2,370' +
          this.gc5 + 'USD' +
          this.gc6
      },
      {
        small: '../../assets/images/banner/romantic.jpg',
        medium: '../../assets/images/banner/romantic.jpg',
        big: '../../assets/images/banner/romantic.jpg',
        description: this.gc1 + 'Romantic Dinner' +
          this.gc2 + 'Yacht Tour' +
          this.gc3 + 'Cancun' +
          this.gc4 + '1,800' +
          this.gc5 + 'USD' +
          this.gc6
      }
    ];

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - SCROLL
  //
  ngAfterViewInit() {
    window.addEventListener('scroll', this.scroll, true);
    this.limit = document.getElementsByTagName('footer')[0].offsetTop;
    this.scrolling();
  }
  //
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }
  //
  // noinspection UnterminatedStatementJS
  scroll = (): void => {
    this.scrolling();
  }

  scrolling() {
    const scrollPos = window.scrollY + window.innerHeight;
    const mc = document.getElementById('more-contact') as HTMLDivElement;
    if ( this.limit > scrollPos ) {
      mc.classList.remove('normal');
    } else {
      mc.classList.add('normal');
    }
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - / SCROLL



  async getPackages() {
    this.packagesList = [];
    this.packagesList.push(
      {
        totalPeople: 20,
        location: 'Isla Mujeres VIP',
        package_name: 'Luxury 55\' Yacht - 6 Hours',
        duration: 6,
        images: [
          '../../assets/images/yacht/yachtSample/0.jpg',
          '../../assets/images/yacht/prestige/exterior 1.jpg',
          '../../assets/images/yacht/yachtSample/1.jpg',
          '../../assets/images/yacht/prestige/exterior 2.jpg',
          '../../assets/images/yacht/yachtSample/2.jpg'],
        features: [
          'Soft drinks, beers, and water bottles',
          'Snack varieties',
          'Towels, tanner and sunscreens',
          'Inflatable sea toys',
          'Docking fee included',
          'Experienced crew',
          'VIP Transportation from your hotel in Cancun and Playa del Carmen'
        ],
        price: 1800,
        package_information: [
          'Transportation included',
          'Up to 20 people',
          'Taxes & fees included'
        ],
        currency: 'USD',
        vipTransportation: true
      }
    );
    this.packagesList.push(
      {
        totalPeople: 20,
        location: 'Isla Mujeres VIP',
        package_name: 'Luxury 55\' Yacht - 8 Hours',
        duration: 8,
        images: [
          '../../assets/images/yacht/yachtSample/1.jpg',
          '../../assets/images/yacht/prestige/exterior 4.jpg',
          '../../assets/images/yacht/prestige/exterior 7.jpg',
          '../../assets/images/yacht/prestige/interior 2.jpg',
          '../../assets/images/yacht/prestige/interior 1.jpg', ],
        features: [
          'Soft drinks, beers, and water bottles',
          'Snack varieties',
          'Towels, tanner and sunscreens',
          'Inflatable sea toys',
          'Docking fee included',
          'Experienced crew',
          'VIP Transportation from your hotel in Cancun and Playa del Carmen'
        ],
        price: 2200,
        package_information: [
          'Transportation included',
          'Up to 20 people',
          'Taxes & fees included'
        ],
        currency: 'USD',
        vipTransportation: true
      }
    );
    this.packagesList.push(
      {
        totalPeople: 4,
        location: 'Isla Mujeres VIP',
        package_name: 'Sunset marriage proposal',
        duration: 3,
        images: [
          '../../assets/images/yacht/yachtSample/3.jpg',
          '../../assets/images/yacht/prestige/exterior 3.jpg',
          '../../assets/images/yacht/prestige/exterior 9.jpg',
          '../../assets/images/yacht/prestige/interior 4.jpg',
          '../../assets/images/yacht/prestige/interior 3.jpg', ],
        features: [
          'Champagne bottle',
          'Docking fee included ',
          'Experienced crew'
        ],
        price: 800,
        package_information: [
          'Up to 4 people',
          'Taxes & fees included'
        ],
        currency: 'USD',
        vipTransportation: false
      }
    );
  }

  onLeft(event) {
    const tElem = event.target as HTMLDivElement;
    //
    if (tElem.classList.contains('disabled')) {
      // Do nothing
    } else {
      goLeft(event, tElem);
    }
    //
  }

  onRight(event) {
    const tElem = event.target as HTMLDivElement;
    //
    if (tElem.classList.contains('disabled')) {
      // Do nothing
    } else {
      goRight(event, tElem);
    }
    //
  }

  onResize(event) {
    setImageContainers('scroll-container');
    //
  }

  onBook(event, nautPack) {
    event.stopPropagation();
    //
    const reservation = new NauticalReservationModel();
    reservation.nautical_package = nautPack;
    this._nautService.setReservation(reservation);
    this._router.navigate(['/nautica.booking']).then();
  }

  onShowInfo(event, offer) {
    //
    this._nautService.fireNauticalOffer(offer);
    openWindow(event, 'w-nautica-tour-details');
  }

  onScroll(event) {
    // noinspection JSUnusedLocalSymbols
    const limitE = document.getElementsByTagName('footer')[0] as HTMLDivElement;
  }

}
