import {Component, DoCheck, OnDestroy, OnInit} from '@angular/core';
import {RoomPersonsModel, SearchModel} from '../../../models';
import {Subscription} from 'rxjs';
import {HotelService} from '../../../services/hotel.service';

declare function closeWindowWithoutEvent(wID: any);

@Component({
  selector: 'app-w-rooms-guests',
  templateUrl: './w-rooms-guests.component.html',
  styleUrls: ['./w-rooms-guests.component.css']
})
export class WRoomsGuestsComponent implements OnInit, OnDestroy, DoCheck {

  roomPersons: RoomPersonsModel[] = this._hotelService._roomPersons;
  subscriptionsObj: Subscription = new Subscription();
  search: SearchModel;

  constructor(private _hotelService: HotelService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptionsObj.unsubscribe();
  }

  ngDoCheck() {
    const reservationCheck = this._hotelService.getReservation();
    if (this._hotelService._triggerRoomWindowChange) {
      if (reservationCheck && reservationCheck.coming_from_search) {
        this.roomPersons = reservationCheck.search.rooms_persons;
      }
      this._hotelService._triggerRoomWindowChange = false;
    }
  }

  updateRooms(operator) {
    if (operator === 'sum' && this.roomPersons.length < 4) {
      this.roomPersons.push({
        adult: 1,
        child: 0,
        ages: []
      });
    }

    if (operator === 'subtract' && this.roomPersons.length > 1) {
      this.roomPersons.pop();
    }
  }

  currentAmount(index) {
    return this.roomPersons[index].adult + this.roomPersons[index].child;
  }

  updateAdults(index, operator) {
    if (operator === 'sum' && this.currentAmount(index) < 7) {
      this.roomPersons[index].adult++;
    }

    if (operator === 'subtract' && this.roomPersons[index].adult > 1) {
      this.roomPersons[index].adult--;
    }
  }

  updateChildren(index, operator) {
    if (operator === 'sum' && this.roomPersons[index].child < 4 && this.currentAmount(index) + 1 <= 7) {
      this.roomPersons[index].child++;
      this.roomPersons[index].ages.push(0);
    }

    if (operator === 'subtract' && this.roomPersons[index].child > 0) {
      this.roomPersons[index].child--;
      this.roomPersons[index].ages.pop();
    }
  }

  updateAges(indexRoom, indexChild, operator) {
    if (operator === 'sum' && this.roomPersons[indexRoom].ages[indexChild] < 18) {
      this.roomPersons[indexRoom].ages[indexChild]++;
    }

    if (operator === 'subtract' && this.roomPersons[indexRoom].ages[indexChild] > 0) {
      this.roomPersons[indexRoom].ages[indexChild]--;
    }
  }

  // - - - - - - - - - - - - - - - - - - - ON CLOSE WINDOW ()
  onCloseWindow() {
    closeWindowWithoutEvent('w-rooms-guests');
  }

  // - - - - - - - - - - - - - - - - - - - ON CANCEL WINDOW ()
  onCancelWindow() {
    closeWindowWithoutEvent('w-rooms-guests');
  }

  // - - - - - - - - - - - - - - - - - - - ON SAVE WINDOW ()
  onSaveWindow() {
    this._hotelService._roomPersons = this.roomPersons;
    this._hotelService.fireRoomPersonsChange(this.roomPersons);
    closeWindowWithoutEvent('w-rooms-guests');
  }

}
