import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {LoginService} from '../../services/login.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appSecurity]'
})
export class SecurityDirective implements OnInit, OnDestroy {

  @Input() appSecurity: string;

  loguedUserRol: string = '';
  loguedEventSubscription: Subscription;

  constructor(private _loginService: LoginService,
              private container: ViewContainerRef,
              private template: TemplateRef<any>) {
  }

  ngOnInit() {
    this.getRole();
    this.checkSecurity(this.appSecurity);
    this.loguedEventSubscription = this._loginService._logged.subscribe(
      (data) => {
        this.getRole();
        this.checkSecurity(this.appSecurity);
      }
    );
  }

  checkSecurity(roles: string) {
    this.container.clear();
    if (roles && this.checkRoles(roles)) {
      this.container.createEmbeddedView(this.template);
    }
  }

  ngOnDestroy() {
    if (this.loguedEventSubscription) {
      this.loguedEventSubscription.unsubscribe();
    }
  }

  getRole() {
    var loguedUserData = this._loginService.getToken();
    if (loguedUserData && loguedUserData.user_data && loguedUserData.user_data.role) {
      this.loguedUserRol = loguedUserData.user_data.role;
    }
  }

  checkRoles(roles: string): boolean {
    var rolArray = roles.split('|');
    for (let r of rolArray) {
      if (r && r == this.loguedUserRol) {
        return true;
      }
    }

    return false;
  }
}
