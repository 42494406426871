import {Component, DoCheck, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {IMyDateRangeModel, IMyDrpOptions} from 'mydaterangepicker';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericModel, ReservationModel, RoomPersonsModel, SearchModel} from '../../models';
import {Subscription} from 'rxjs';
import {CommonService} from '../../services/common.service';
import {HotelService} from '../../services/hotel.service';
import {RequestParams} from '../../core/api/api.model';
import {GeneralUtil} from '../../core/util/general.util';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';

declare function openWindowWithoutEvent(wID: any);

declare function suma(event: any, maxVal: any);

declare function resta(event: any, minVal: any);

@Component({
  selector: 'app-form-hotel-search',
  templateUrl: './form-hotel-search.component.html',
  styleUrls: ['./form-hotel-search.component.css']
})
export class FormHotelSearchComponent implements OnInit, OnDestroy {


  currDate: Date = new Date();

  myDateRangePickerOptions: IMyDrpOptions = {
    // other options...
    width: '100%',
    dateFormat: 'mm/dd/yyyy',
    selectBeginDateTxt: 'Check In',
    selectEndDateTxt: 'Check Out',
    editableDateRangeField: false,
    openSelectorOnInputClick: true,
    indicateInvalidDateRange: true,
    disableUntil: {year: this.currDate.getFullYear(), month: this.currDate.getMonth() + 1, day: this.currDate.getDate()},
    showClearDateRangeBtn: true,
    sunHighlight: false
  };
  searchForm: FormGroup;
  destinations: GenericModel[];
  subscriptionObject: Subscription;
  search: SearchModel;
  bookingFilters: RequestParams;

  roomPersons: RoomPersonsModel[];

  // savedForm: boolean = false;

  constructor(
    private _router: Router,
    private _commonService: CommonService,
    private _hotelService: HotelService,
    private __spinnerService: NgxSpinnerService) {

  }

  // - - - - - - - - - - - - - - Implement Functions

  ngOnInit() {
    this.search = this._hotelService.getReservation() ? this._hotelService.getReservation().search : null;
    this.subscriptionObject = new Subscription();
    if (this.search) {
      this.roomPersons = this.search.rooms_persons;
      this.fillFormUpdate().catch((err) => console.error(err.message));
    } else {
      this.roomPersons = [{adult: 1, child: 0, ages: []}];
      this.fillForm().catch((err) => console.error(err.message));
    }
    this.getDestinations();
  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

  // - - - - - - - - - - - - - - / Implement Functions

  // - - - - - - - - - - - - - - Form Functions

  async fillForm() {
    this.searchForm = new FormGroup({
      'destination_id': new FormControl('', [Validators.required]),
      'check_in': new FormControl(null, [Validators.required]),
      'check_out': new FormControl(null, Validators.required),
      'myDateRange': new FormControl(null, Validators.required),
      'rooms_number': new FormControl(this.roomPersons.length, Validators.required),
      'rooms_persons': new FormControl(this.roomPersons, [Validators.required])
    });
  }

  async fillFormUpdate() {
    this.search.rooms_persons = this.roomPersons;
    const items: FormGroup[] = [];
    for (let i = 0; i < this.search.rooms_persons.length; i++) {
      items.push(new FormGroup({
        'adult': new FormControl(this.search.rooms_persons[i].adult, Validators.required),
        'child': new FormControl(this.search.rooms_persons[i].child),
        'ages': new FormControl(this.search.rooms_persons[i].ages),
      }));
    }
    this.searchForm = new FormGroup({
      'destination_id': new FormControl(this.search.destination_id, [Validators.required]),
      'check_in': new FormControl(this.search.check_in, [Validators.required]),
      'check_out': new FormControl(this.search.check_out, Validators.required),
      'myDateRange': new FormControl(this.search.myDateRange, Validators.required),
      'rooms_number': new FormControl(this.search.rooms_persons.length, Validators.required),
      'rooms_persons': new FormArray(items)
    });
  }

  // - - - - - - - - - - - - - - / Form Functions

  // - - - - - - - - - - - - - - Destination Functions


  getDestinations() {
    this.subscriptionObject.add(this._commonService.getDestinations().subscribe(
      (data) => {
        this.destinations = data.data;
      }
    ));
  }

  findDestinationById(id) {
    let destSelect = null;
    this.destinations.map(
      (dest) => {
        if (dest.id === id) {
          destSelect = dest;
        }
      }
    );
    return destSelect;
  }

  // - - - - - - - - - - - - - - / Destination Functions

  // - - - - - - - - - - - - - - Date Functions

  onDateRangeChanged(event: IMyDateRangeModel) {
    const checkIn = this.resolveDate(event.beginDate);
    const checkOut = this.resolveDate(event.endDate);
    this.searchForm.get('check_in').setValue(checkIn);
    this.searchForm.get('check_out').setValue(checkOut);
  }

  // noinspection JSMethodCanBeStatic
  resolveDate(date) {
    const day = date.day < 10 ? '0' + date.day : date.day;
    const month = date.month < 10 ? '0' + date.month : date.month;
    const year = date.year;
    return year + '-' + month + '-' + day;
  }

  // - - - - - - - - - - - - - - / Date Functions

  // - - - - - - - - - - - - - - / Rooms Functions

  updateRooms(operator) {
    if (operator === 'sum' && this.roomPersons.length < 4) {
      this.roomPersons.push({
        adult: 1,
        child: 0,
        ages: []
      });
    }

    if (operator === 'subtract' && this.roomPersons.length > 1) {
      this.roomPersons.pop();
    }
  }

  currentAmount(index) {
    return this.roomPersons[index].adult + this.roomPersons[index].child;
  }

  updateAdults(index, operator) {
    if (operator === 'sum' && this.currentAmount(index) < 7) {
      this.roomPersons[index].adult++;
    }

    if (operator === 'subtract' && this.roomPersons[index].adult > 1) {
      this.roomPersons[index].adult--;
    }
  }

  updateChildren(index, operator) {
    if (operator === 'sum' && this.roomPersons[index].child < 4 && this.currentAmount(index) + 1 <= 7) {
      this.roomPersons[index].child++;
      this.roomPersons[index].ages.push(0);
    }

    if (operator === 'subtract' && this.roomPersons[index].child > 0) {
      this.roomPersons[index].child--;
      this.roomPersons[index].ages.pop();
    }
  }

  updateAges(indexRoom, indexChild, operator) {
    if (operator === 'sum' && this.roomPersons[indexRoom].ages[indexChild] < 18) {
      this.roomPersons[indexRoom].ages[indexChild]++;
    }

    if (operator === 'subtract' && this.roomPersons[indexRoom].ages[indexChild] > 0) {
      this.roomPersons[indexRoom].ages[indexChild]--;
    }
  }

  onClearRoom(index) {
    if (this.roomPersons.length > 1) {
      this.roomPersons.splice(index, 1);
    }
  }

  // - - - - - - - - - - - - - - / Rooms Functions

  // - - - - - - - - - - - - - - Submit Functions

  // noinspection JSUnusedGlobalSymbols
  findInvalidControlsV0() {
    const controls = this.searchForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        GeneralUtil.errorMessage(this.searchForm.get(name).value);
      }
    }
  }


  // noinspection JSMethodCanBeStatic
  invalidFormMessage(errorMessage) {
    Swal.fire({
      title: 'Missing Information',
      html: errorMessage,
      type: 'error',
      allowOutsideClick: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then();
    // Forced
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

  findInvalidControls() {
    const errorMap = {rooms: 'Guests Info', name: 'Name', last_name: 'Last Name', email: 'Email', phone_number: 'Phone Number'};
    let errorMessage = 'Your Booking Details have this missing details:<br><div style="width: 50%; margin: auto"><p align="left">';
    const controls = this.searchForm.controls;
    for (const nameC in controls) {
      if (controls[nameC].invalid) {
        if (nameC === 'destination_id') {
          errorMessage += '&#8226; <b>Destination</b><br>';
        }
        if (nameC === 'check_in') {
          errorMessage += '&#8226; <b>Check In</b><br>';
        }
        if (nameC === 'check_out') {
          errorMessage += '&#8226; <b>Check Out</b><br>';
        }
        if (nameC === 'myDateRange') {
          errorMessage += '&#8226; <b>Date Range</b><br>';
        }
      }
    }
    errorMessage += '</p></div>';
    // errorMessage = errorMessage.slice(0, -2) + '!';
    this.invalidFormMessage(errorMessage);
  }


  getHotels(searchT: SearchModel) {
    const filter = [];
    filter.push({field: 'destination_id', op: '=', data: searchT.destination_id});
    filter.push({field: 'check_in', op: '=', data: searchT.check_in});
    filter.push({field: 'check_out', op: '=', data: searchT.check_out});
    filter.push({field: 'rooms_number', op: '=', data: searchT.rooms_number});
    const rooms_persons_temp = {};
    let adult = 0;
    let children = 0;
    for (let i = 0; i < searchT.rooms_persons.length; i++) {
      const ages = [];
      for (let k = 0; k < searchT.rooms_persons[i].child; k++) {
        ages.push(searchT.rooms_persons[i].ages[k]);
      }
      rooms_persons_temp[i + 1] = {
        adult: searchT.rooms_persons[i].adult,
        child: searchT.rooms_persons[i].child,
        ages: ages,
      };
      adult += searchT.rooms_persons[i].adult;
      children += searchT.rooms_persons[i].child;

    }

    searchT.adults = adult;
    searchT.childrens = children;
    const newReservation = this._hotelService.getReservation();
    newReservation.search = searchT;
    newReservation.offer = null;
    newReservation.hotel = null;
    this._hotelService.setReservation(newReservation);

    console.log(rooms_persons_temp);

    filter.push({field: 'rooms_persons', op: '=', data: rooms_persons_temp });

    console.log(filter);

    const params: RequestParams = {
      filters: filter
    };
    this.__spinnerService.show().then();
    this.subscriptionObject.add(this._hotelService.getHotels(params).subscribe(
      (data) => {
        this.__spinnerService.hide().then();
        const hotels = data.data;
        if (hotels.length === 0) {
          const msgTxt = '<h3 class="title text-uc text-c4 tac mt-1 mb-2">There are not available offers</h3>'
            + '<p class="mb-2">We have not found any results based on your search criteria.'
            + ' <br> Please try changing your search criteria </p>';
          GeneralUtil.infoMessage2(msgTxt, 5000);
        } else {
          newReservation.list_hotels = hotels;
          newReservation.actual_time = new Date().getTime();
          this._hotelService.setReservation(newReservation);
          this._hotelService.fireHotelSearch();
          this._router.navigate(['/hotels']).catch((err) => console.error(err.message));
        }

      },
      (error) => {
        this.__spinnerService.hide().then();
        if (error.name && error.name === 'TimeoutError') {
          Swal.fire({
            html: '<h3 class="title text-uc text-c4 tac mt-1 mb-2">A time out error occurred</h3>' +
              '<p class="mb-1">The provider is temporarily out of service.</p><p class="mb-2">Repeat your search or start a new one.</p>',
            type: 'error',
            showCancelButton: true,
            cancelButtonText: 'NEW SEARCH',
            confirmButtonText: 'REPEAT SEARCH',
            customClass: {
              confirmButton: 'btn btn-sm btn-3rd col-45-1 px-0',
              cancelButton: 'btn btn-sm btn-2nd col-45-1 px-0',
              actions: 'full-w'
            }
          }).then((result) => {
            if (result.value) {
              const searchAgain = this._hotelService.getReservation().search;
              this.getHotels(searchAgain);
            }
          });
        }
      }
    ));
  }

  patchRoomPersons() {
    const searchModel = this.searchForm.value;
    const items: FormGroup[] = [];
    for (let i = 0; i < this.roomPersons.length; i++) {
      items.push(new FormGroup({
        'adult': new FormControl(this.roomPersons[i].adult, Validators.required),
        'child': new FormControl(this.roomPersons[i].child),
        'ages': new FormControl(this.roomPersons[i].ages),
      }));
    }
    this.searchForm = new FormGroup({
      'destination_id': new FormControl(searchModel.destination_id, [Validators.required]),
      'check_in': new FormControl(searchModel.check_in, [Validators.required]),
      'check_out': new FormControl(searchModel.check_out, Validators.required),
      'myDateRange': new FormControl(searchModel.myDateRange, Validators.required),
      'rooms_number': new FormControl(this.roomPersons.length, Validators.required),
      'rooms_persons': new FormArray(items)
    });
  }

  onSubmit() {
    this._hotelService.resetReservation();

    this.searchForm.get('rooms_number').setValue(this.roomPersons.length);

    this.patchRoomPersons();

    if (this.searchForm.valid && this.roomPersons !== null && this.roomPersons.length > 0) {
      const searchModel = this.searchForm.value;
      const filterParam = [];

      filterParam.push([
        'destination_id',
        '=',
        searchModel.destination_id
      ]);

      filterParam.push([
        'check_in',
        '=',
        searchModel.check_in
      ]);

      filterParam.push([
        'check_out',
        '=',
        searchModel.check_out
      ]);

      filterParam.push([
        'rooms_number',
        '=',
        searchModel.rooms_number
      ]);

      // filterParam.push([
      //   'rooms_persons',
      //   '=',
      //   searchModel.rooms_persons
      // ]);

      this.bookingFilters = {
        params: {filter: filterParam},
        search: {},
        filters: []
      };

      const search = new SearchModel();
      const model = Object.assign({}, search, this.searchForm.value);
      // noinspection SillyAssignmentJS
      model.check_in = model.check_in;
      // noinspection SillyAssignmentJS
      model.check_out = model.check_out;
      model.destination = this.findDestinationById(model.destination_id);
      const reservation = new ReservationModel();
      reservation.search = model;
      reservation.coming_from_search = true;
      this._hotelService.setReservation(reservation);
      this._hotelService.setParam(this.bookingFilters);
      this._hotelService._roomPersons = searchModel.rooms_persons;
      this._hotelService._currDates = [searchModel.check_in, searchModel.check_out];
      console.log(model);
      this.getHotels(model);
    } else {
      this.findInvalidControls();
      GeneralUtil.validateAllFormFields(this.searchForm);
    }
  }

  // - - - - - - - - - - - - - - / Submit Functions


}
