import {HotelModel} from '.';

export class ReservationResultModel {
  adults: number;
  base_price: string;
  check_in: string;
  check_out: string;
  childrens: number;
  city_id: number;
  currency: CurrencyModel;
  currency_id: number;
  date: string;
  ggt_client_code: string;
  ggt_id: number;
  ggt_reference: string;
  ggt_state: string;
  hotel: HotelModel;
  hotel_search_code: string;
  latitude: string;
  longitude: string;
  nights: number;
  operator_id: null;
  provider_id: number;
  remarks: Array<string>;
  reservationPayment: ReservationPaymentResultModel;
  reservationRooms: ReservationRoomResultModel[];
  reservation_code: string;
  state: string;
  total_price: number;
  url: string;
  user_id: number;
}

export class CurrencyModel {
  id: number;
  name: string;
  abbreviation: string;
}

export class ReservationPaymentResultModel {
  id: number;
  payment_method: string;
  state: string;
  charge_token: string;
  refund_token: string;
  refund_amount: string;
  card_token: string;
  last_4: string;
  amount: number;
  currency_abbreviation: string;
  reservation_id: number;
}

export class ReservationRoomResultModel {
  id: number;
  reservation_id: number;
  room_id: number;
  flat_price: number;
  price: number;
  type: string;
  adults: number;
  childrens: number;
  currency_id: number;
  reservationRoomPersons: ReservationRoomPersonResultModel[];
}

export class ReservationRoomPersonResultModel {
  id: number;
  reservation_room_id: number;
  ggt_person_id: number;
  type: string;
  first_name: string;
  last_name: string;
  age: number;
}
