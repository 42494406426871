import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NauticalService} from '../../services/nautical.service';
import {NauticalModel, NauticalReservationModel} from '../../models/nautical.model';
import {CountryModel, UserInfoModel, UserModel} from '../../models';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {INTEGER_VALIDATOR} from '../../core/util/form-validators.util';
import {Subscription} from 'rxjs';
import {CommonService} from '../../services/common.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LoginService} from '../../services/login.service';
import {UserService} from '../../services/user.service';
import {GeneralUtil} from '../../core/util/general.util';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {INgxMyDpOptions} from 'ngx-mydatepicker';

declare function toggleSlider(event);
declare function openWindowWithoutEvent(wID: any);
declare function clearNav(): any;
declare function restaTime(event:any, am:any, pm:any);
declare function sumaTime(event:any, am:any, pm:any);

// noinspection SpellCheckingInspection
declare function restaTime(event: any, am: any, pm: any);

// noinspection SpellCheckingInspection
declare function sumaTime(event: any, am: any, pm: any);

@Component({
  selector: 'app-booking-nautical',
  templateUrl: './booking-nautical.component.html',
  styleUrls: ['./booking-nautical.component.css']
})
export class BookingNauticalComponent implements OnInit, AfterViewInit, OnDestroy {

  subscriptionObject: Subscription;
  subscriptionLogin: Subscription;
  subscriptionReservation: Subscription;

  myOptionsCheckOut: INgxMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
    firstDayOfWeek: 'su',
    markCurrentDay: true,
    closeSelectorOnDocumentClick: true,
    disableHeaderButtons: true
  };

  reservation: NauticalReservationModel;

  nautical_package: NauticalModel;
  guestForm: FormGroup;
  countries: CountryModel[];
  userLogged: UserModel;
  book_info: UserModel;
  disableInput: boolean = false;
  calendarsFlag = true;

  formSelected: number = 1;
  limit = 0;
  Mer = 'PM';

  @ViewChild('checkedUser') checkedComponent: ElementRef;

  constructor(
    private _router: Router,
    private _nautService: NauticalService,
    private _commonService: CommonService,
    private _spinner: NgxSpinnerService,
    private _loginService: LoginService,
    private _userService: UserService) {
  }

  ngOnInit() {
    //
    clearNav();
    document.getElementById('mm4').classList.add('active');

    //
    this.subscriptionObject = new Subscription();
    this.subscriptionLogin = new Subscription();
    this.subscriptionReservation = new Subscription();
    this.userLogged = this._loginService.getUserInfo();

    this.checkingData();
    this.listenLogin();

    this.reservation = this._nautService.getReservation();

    this.getPhoneCodes();
    this.setInitialDate();

    if (this.userLogged) {
      this.formSelected = 2;
      this.disableInput = true;
      this.fillForm(this.userLogged);
    } else {
      this.formSelected = 1;
      this.disableInput = false;
      if (this.book_info) {
        this.fillForm(this.book_info);
      } else {
        this.fillForm(null);
      }
    }
  }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - SCROLL
    //
    ngAfterViewInit(){
      window.addEventListener('scroll', this.scroll, true);
      this.limit = document.getElementsByTagName('footer')[0].offsetTop;
      this.scrolling();
    }
    //
    ngOnDestroy() {
      window.removeEventListener('scroll', this.scroll, true);
    }
    //
    scroll = (): void => {
      this.scrolling();
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - / SCROLL

    scrolling(){
      var scrollpos = window.scrollY + window.innerHeight;
      var mc = document.getElementById('more-contact') as HTMLDivElement;
      if ( this.limit > scrollpos ){
        mc.classList.remove('normal');
      } else {
        mc.classList.add('normal');
      }
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - / SCROLL

  listenLogin() {
    this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
      (data) => {
        switch (data) {
          case null:
          case undefined:
            this.userLogged = null;
            this.onSlider(1);
            break;
          default:
            this.userLogged = data;
            this.onSlider(2);
            break;
        }
      })
    );
  }

  checkingData() {
    this.reservation = this._nautService.getReservation();

    switch (this.reservation) {
      case null:
      case undefined:
        this._nautService.resetReservation();
        // noinspection SpellCheckingInspection
        this._router.navigate(['/nautica']).then();
        break;
      default:
        break;
    }

    this.nautical_package = this.reservation.nautical_package;

    switch (this.nautical_package) {
      case null:
      case undefined:
        this._nautService.resetReservation();
        // noinspection SpellCheckingInspection
        this._router.navigate(['/nautica']).then();
        break;
      default:
        break;
    }
  }

  getPhoneCodes() {
    this.subscriptionObject.add(this._commonService.getPhoneCodes().subscribe(
      (data) => {
        this.countries = data.data;
      }
    ));
  }

  setInitialDate() {
    const today = new Date();
    this.myOptionsCheckOut.disableUntil = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
  }

  onClickCalendar(event, dpRef) {
    if (this.calendarsFlag) {
      dpRef.toggleCalendar();
    } else {
      this.calendarsFlag = true;
    }
  }

  onCheckOutChanged(event) {
    const date: Date = new Date(event.date.year + '-' + event.date.month + '-' + event.date.day);
    this.calendarsFlag = false;
    // this.calcNights(date);
  }

  disableInputs() {
    this.guestForm.get('name').disable();
    this.guestForm.get('last_name').disable();
    this.guestForm.get('email').disable();
    this.guestForm.get('phone_number').disable();
  }

  enableInputs() {
    this.guestForm.get('name').enable();
    this.guestForm.get('last_name').enable();
    this.guestForm.get('email').enable();
    this.guestForm.get('phone_number').enable();
  }

  fillForm(user: UserModel) {
    if (this.formSelected === 1) {
      user = null;
    }
    let country = null;
    let area = null;
    let number = null;
    if (user) {
      const phoneNumberArray = user.phone_number.split('-');
      country = phoneNumberArray[0];
      area = phoneNumberArray[1];
      number = phoneNumberArray[2];
    }
    let totalGuests;
    if (this.nautical_package.totalPeople === 4) {
      totalGuests = '2 to 4';
    } else {
      totalGuests = '2 to 6';
    }

    this.guestForm = new FormGroup({
      'name': new FormControl(user ? user.name : null, [Validators.required]),
      'last_name': new FormControl(user ? user.last_name : null, [Validators.required]),
      'email': new FormControl(user ? user.email : null, [Validators.required]),
      'phone_number': new FormGroup({
        'country': new FormControl(country, [Validators.required]),
        'area': new FormControl(area, [Validators.required, INTEGER_VALIDATOR, Validators.minLength(1), Validators.maxLength(3)]),
        'number': new FormControl(number, [Validators.required, INTEGER_VALIDATOR, Validators.minLength(5), Validators.maxLength(7)]),
      }),
      'guests': new FormControl(totalGuests, [Validators.required]),
      'date': new FormControl(null, [Validators.required]),
      'DHour': new FormControl(12, [Validators.required]),
      'DMinute': new FormControl('00', [Validators.required]),
      'DPeriod': new FormControl('PM', [Validators.required]),
      'harbour': new FormControl('Puerto Cancun', [Validators.required]),
      'hotel': new FormControl(null),
      'address': new FormControl(null),
      'extra': new FormControl(null),
    });

    if (this.disableInput === true) {
      this.disableInputs();
    }

  }

  onSlider(id) {
    this.formSelected = id;
    if (this.formSelected === 1) {
      this.disableInput = false;
      this.enableInputs();
      this.fillForm(null);
    } else {
      if (this.userLogged) {
        this.disableInput = true;
        this.disableInputs();
        this.fillForm(this.userLogged);
      } else {
        this.goLoginOrRegister();
      }
    }
  }

  goLoginOrRegister() {
    Swal.fire({
      confirmButtonText: 'CANCEL',
      html: '<h3 class="title text-uc text-c4 tac mt-1 mb-2">Login or Register</h3>' +
        '<p>You are not logged in the system.</p><div class="cb mb-2"></div>' +
        '<div class="col col-45-1"><button id="register" class="btn btn-nor btn-3rd full-w">' +
        'REGISTER' +
        '</button></div>' +
        '<div class="col col-1-1 tac pt-2"><p>or</p></div>' +
        '<div class="col col-45-1"><button id="login" class="btn btn-nor btn-2nd full-w">' +
        'LOG IN' +
        '</button></div>' +
        '<div class="cb mb-3"></div>',
      customClass: {
        confirmButton: 'btn btn-sm btn-5th full-w'
      },
      onBeforeOpen: () => {
        const content = Swal.getContent();
        const $ = content.querySelector.bind(content);

        const login = $('#login');
        const register = $('#register');

        login.addEventListener('click', () => {
          openWindowWithoutEvent('w-login');
          Swal.close();
        });

        register.addEventListener('click', () => {
          this._router.navigate(['/register'], {queryParams: {redirect: '/hotel.booking'}}).then(() => Swal.close());
        });

      },
      onClose: () => {
        this.checkedComponent.nativeElement.click();
      }
    }).then();
  }

  // noinspection JSMethodCanBeStatic
  invalidFormMessage(errorMessage) {
    Swal.fire({
      title: 'Missing Information',
      html: errorMessage,
      type: 'error',
      allowOutsideClick: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then();
    // Forced
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

  findInvalidControls() {
    let errorMessage = 'Your Booking Details have this missing details:<br><div style="width: 50%; margin: auto"><p align="left">';
    const controls = this.guestForm.controls;
    for (const nameC in controls) {
      if (controls[nameC].invalid) {
        if (nameC === 'name') {
          errorMessage += '&#8226; <b>Main Guest\'s Name</b><br>';
        }
        if (nameC === 'last_name') {
          errorMessage += '&#8226; <b>Main Guest\'s Last Name</b><br>';
        }
        if (nameC === 'phone_number') {
          errorMessage += '&#8226; <b>Main Guest\'s Phone Number</b><br>';
        }
        if (nameC === 'date') {
          errorMessage += '&#8226; <b>Departure Date</b><br>';
        }
      }
    }
    errorMessage += '</p></div>';
    // errorMessage = errorMessage.slice(0, -2) + '!';
    this.invalidFormMessage(errorMessage);
  }

  resolveAfterXSeconds(x, time) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(x);
      }, time);
    });
  }

  onSubmit() {
    if (this.guestForm.valid) {


      if (!GeneralUtil.validateEmail(this.guestForm.get('email').value)) {
        GeneralUtil.errorMessage('Invalid Email Address!');
        return;
      }

      const guestForm = this.guestForm.value;

      const userGuest = {
        email: this.guestForm.get('email').value,
        last_name: this.guestForm.get('name').value,
        name: this.guestForm.get('last_name').value,
        phone_number: this.guestForm.get('phone_number').get('country').value + '-'
          + this.guestForm.get('phone_number').get('area').value + '-'
          + this.guestForm.get('phone_number').get('number').value,
        user_info: UserInfoModel
      };

      // noinspection SpellCheckingInspection
      const body = {
        name_paq_nautica: this.reservation.nautical_package.package_name,
        user_id: null,
        no_guest: guestForm.guests,
        departure_date: guestForm.date.formatted,
        departure_time: guestForm.DHour + ':' + guestForm.DMinute + ' ' + guestForm.DPeriod,
        departure_harbour: guestForm.harbour,
        transportation_hotel: guestForm.hotel,
        includes: this.reservation.nautical_package.features
      };

      this._spinner.show().then();
      this.subscriptionObject.add(this._userService.registerGuest(userGuest).subscribe(
        (data) => {
          body.user_id = data.data.id;
          this.subscriptionReservation.add(this._nautService.makeAReservation(body).subscribe(
            (dataR) => {
              this._spinner.hide().then();
              GeneralUtil.successMessage(dataR.message);
              this.resolveAfterXSeconds(20, 2500).then(
                () => {
                  this._nautService.resetReservation();
                  this._router.navigate(['/home']).then();
                }
              );
            }, (errorR) => {
              this._spinner.hide().then();
              GeneralUtil.errorMessage(errorR.message);
            }
          ));
        }, (error) => {
          this._spinner.hide().then();
          GeneralUtil.errorMessage(error.error.message);
        })
      );
    } else {
      this.findInvalidControls();
      GeneralUtil.validateAllFormFields(this.guestForm);
    }
  }

  // - - - - - - - - DEPARTURE TIME *

  setMer(eI) {
    const eInp = eI.closest('.input-group').getElementsByTagName('input')[0] as HTMLInputElement;
    // tslint:disable-next-line:radix
    const eHour = parseInt(eInp.value);
    if (eHour > 7 && eHour < 12) {
      this.Mer = 'AM';
    } else {
      this.Mer = 'PM';
    }
    const setMer = document.getElementById('bnMer') as HTMLInputElement;
    setMer.value = this.Mer;
    this.guestForm.get('DHour').patchValue(eHour);
    this.guestForm.get('DPeriod').patchValue(this.Mer);
  }

  dwnHour(event, am, pm) {
    restaTime(event, am, pm);
    const eI = event.target as HTMLButtonElement;
    this.setMer(eI);
  }

  upHour(event, am, pm) {
    sumaTime(event, am, pm);
    const eI = event.target as HTMLButtonElement;
    this.setMer(eI);
  }

  subsMin(event) {
    const eI = event.target as HTMLButtonElement;
    const eInp = eI.closest('.input-group').getElementsByTagName('input')[0] as HTMLInputElement;
    // tslint:disable-next-line:radix
    let vMin = parseInt(eInp.value) - 15;
    vMin = vMin < 0 ? 45 : vMin;
    if (vMin === 0) {
      eInp.value = '00';
    } else {
      eInp.value = vMin.toString();
    }
    this.guestForm.get('DMinute').patchValue(eInp.value);
  }

  restaMin(event){
    var eI = event.target as HTMLButtonElement;
    var eInp = eI.closest('.input-group').getElementsByTagName('input')[0] as HTMLInputElement;
    var vMin = parseInt(eInp.value) - 15;
    vMin = vMin < 0 ? 45 : vMin;
    eInp.value =  vMin.toString();
  }

  sumaMin(event){
    var eI = event.target as HTMLButtonElement;
    var eInp = eI.closest('.input-group').getElementsByTagName('input')[0] as HTMLInputElement;
    var vMin = parseInt(eInp.value) +15;
    vMin = vMin > 45 ? 0 : vMin;
    if ( vMin == 0 ){
      eInp.value =  '00';
    } else {
      eInp.value =  vMin.toString();
    }
    this.guestForm.get('DMinute').patchValue(eInp.value);
  }

  // - - - - - - - - / DEPARTURE TIME *

}
