import {Component, OnInit} from '@angular/core';
import {NauticalService} from '../../services/nautical.service';
import {NauticalModel, NauticalReservationModel} from '../../models/nautical.model';

@Component({
  selector: 'app-summary-nautical',
  templateUrl: './summary-nautical.component.html',
  styleUrls: ['./summary-nautical.component.css']
})
export class SummaryNauticalComponent implements OnInit {

  reservation: NauticalReservationModel;

  nautical_package: NauticalModel;

  constructor(
    private _nautService: NauticalService) {
  }

  ngOnInit() {
    this.reservation = this._nautService.getReservation();
    this.nautical_package = this.reservation.nautical_package;
  }

  onInfo(event, id) {
    return;
  }

}
