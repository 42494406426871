import {Injectable} from '@angular/core';
import {ApiService} from '../core/api/api.service';
import {RequestParams} from '../core/api/api.model';
import {Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {apiRoutes} from '../api-routing';
import {ChildAge, HotelModel, OfferModel, ReservationModel, RoomPersonsModel} from '../models';
import {StoreUtil} from '../core/util/store.util';
import {ReservationResultModel} from '../models/reservation-result.model';
import {any} from 'codelyzer/util/function';


@Injectable()
export class HotelService {

  // New Flags

  // Booking Summary Flags and Data
  _bookingOfferSummary: boolean = false;

  //

  _roomPersons: RoomPersonsModel[] = [{adult: 1, child: 0, ages: []}];
  _triggerRoomChange: boolean = false;
  _triggerRoomWindowChange: boolean = false;
  _triggerReservationWindow: boolean = false;
  _triggerOfferChange: boolean = false;
  _triggerOfferSearch: boolean = false;
  _triggerPaymentWindow: boolean = false;
  _paramsHotel: RequestParams;
  _currHotel: any;
  _currDates: string[];
  _currOffer: any;

  reservation: ReservationModel = null;
  reservationResult: ReservationResultModel = null;

  _fireHotelSearch: Subject<any> = new Subject<any>();

  _fireOpenDetailWindow: Subject<any> = new Subject<any>();
  _fireOpenOfferWindow: Subject<any> = new Subject<any>();

  _fireOpenSetRoomsWindow: Subject<any> = new Subject<any>();
  _fireCloseSetRoomsWindow: Subject<any> = new Subject<any>();

  _fireOpenAddGuestWindow: Subject<HotelModel> = new Subject<HotelModel>();
  _fireCloseAddGuestWindow: Subject<HotelModel> = new Subject<HotelModel>();
  _fireOpenMapWindow: Subject<HotelModel> = new Subject<HotelModel>();
  _fireOpenGalleryWindow: Subject<any> = new Subject<any>();
  _fireOfferChange: Subject<any> = new Subject<any>();

  /*_refreshAdminView: Subject<any> = new Subject<any>(); */

  constructor(
    private _apiService: ApiService
  ) {
  }

  static setReservationResult(r: ReservationResultModel) {
    StoreUtil.objectToStore('reservationResult', r);
  }

  static getReservationResult() {
    return StoreUtil.objectFromStore('reservationResult');
  }

  static resetReservationResult() {
    StoreUtil.remove('reservationResult');
  }

  setReservation(r: ReservationModel) {
    this._triggerRoomWindowChange = true;
    StoreUtil.objectToStore('reservation', r);
    this.reservation = r;
  }

  getReservation() {
    this.reservation = StoreUtil.objectFromStore('reservation');
    return this.reservation;

  }

  resetReservation() {
    StoreUtil.remove('reservation');
    this.reservation = null;
  }

  setParam(r) {
    this._paramsHotel = r;
    this._triggerReservationWindow = true;
  }

  onOpenDetailWindow(hotelSelected: HotelModel, tabId: number = 1) {
    this._fireOpenDetailWindow.next(
      {
        hotel: hotelSelected,
        tabId: tabId
      });
  }

  onOpenOfferWindow(hotelSelected: HotelModel, DateRange, tabId: number = 1) {
    this._fireOpenOfferWindow.next(
      {
        hotel: hotelSelected,
        tabId: tabId,
        dateRange: DateRange
      });
  }

  resetData() {
    this._currHotel = null;
    this._currHotel = any;
  }

  onOpenSetRoomsWindow(roomPerson: RoomPersonsModel[]) {
    this._fireOpenSetRoomsWindow.next(roomPerson);
  }

  onCloseSetRoomsWindow(obj: ChildAge) {
    this._fireCloseSetRoomsWindow.next(obj);
  }

  onOpenAddGuestWindow() {
    this._fireOpenAddGuestWindow.next(null);
  }

  onCloseAddGuestWindow() {
    this._fireCloseAddGuestWindow.next(null);
  }

  onOpenMapWindow(hotelSelected: HotelModel) {
    this._fireOpenMapWindow.next(hotelSelected);
  }

  onOpenGalleryWindow(reservation: ReservationResultModel) {
    this._fireOpenGalleryWindow.next(reservation);
  }

  getHotels(params: RequestParams = {}): Observable<any> {
    return this._apiService.getLimit(apiRoutes.r_hotel_client_hotels, params);
  }

  getHotelDetails(params: RequestParams = {}): Observable<any> {
    return this._apiService.get(apiRoutes.r_hotel_client_hotels_show, params);
  }

  fireOfferChange(offer: OfferModel) {
    this._fireOfferChange.next(offer);
  }

  fireHotelSearch() {
    this._fireHotelSearch.next();
  }

  getPriceBreakDown(hotel_search_code: string): Observable<any> {
    const params: RequestParams = {
      search: {
        hotel_search_code: hotel_search_code
      }
    };
    return this._apiService.get(apiRoutes.r_hotel_reservations_get_price_breakdown_get, params);
  }

  makeAReservation(reserv: any) {
    return this._apiService.post(apiRoutes.r_hotel_reservations_post, reserv).pipe(
      map(
        (data) => {
          this.reservationResult = data.object;
          return data;
        }
      )
    );
  }

  getReservations(params: RequestParams = {}) {
    return this._apiService.get(apiRoutes.r_hotel_reservations_get, params);
  }

  fireRoomPersonsChange(roomPersons: RoomPersonsModel[] = []) {
    this._triggerRoomChange = true;
    this._roomPersons = roomPersons;
  }

  getCurrHotel() {
    return this._currHotel;
  }

  getHotelList() {
    return this._apiService.getHotelDesktopList(apiRoutes.r_hotel_client_hotels, this._paramsHotel);
  }

  getOffer(quoteId, offerId) {
    const finalPath = apiRoutes.r_hotel_quote_get + '/' + quoteId + '/' + offerId;
    return this._apiService.getSimple(finalPath);
  }

  checkGGTBookingValuation(params: RequestParams = {}): Observable<any> {
    return this._apiService.get(apiRoutes.r_ggt_booking_valuation, params);
  }

  checkHDOBookingValuation(params: RequestParams = {}): Observable<any> {
    return this._apiService.get(apiRoutes.r_hdo_booking_valuation, params);
  }
}
