export class LoguedUserModel {
  activation_code: string;
  created_at: string;
  email: string;
  email_verified_at: string;
  id: number;
  last_name: string;
  name: string;
  phone_number: string;
  remember_token: string;
  updated_at: string;
}



