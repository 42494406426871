import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {HotelService} from '../../services/hotel.service';
import {
  CountryModel,
  GuestModel,
  HotelDetailModel,
  HotelInfoModel,
  HotelModel,
  OfferModel,
  ReservationModel,
  RoomPersonsModel,
  SearchModel,
  UserInfoModel,
  UserModel
} from '../../models';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {Subscription} from 'rxjs';
import {GeneralUtil} from '../../core/util/general.util';
import {LoginService} from '../../services/login.service';
import {INTEGER_VALIDATOR, TEXT_VALIDATOR} from '../../core/util/form-validators.util';
import {CommonService} from '../../services/common.service';
import {UserService} from '../../services/user.service';
import * as _ from 'lodash';
import {QuoteModel} from '../../models/quote.model';
import {RequestParams} from '../../core/api/api.model';

declare function openWindowWithoutEvent(wID: any);

declare function clearNav(): any;

@Component({
  selector: 'app-booking-hotel',
  templateUrl: './booking-hotel.component.html',
  styleUrls: ['./booking-hotel.component.css']
})
export class BookingHotelComponent implements OnInit, OnDestroy {

  subscriptionObject: Subscription;
  subscriptionLogin: Subscription;
  subscriptionQuoteData: Subscription;
  subscriptionPhones: Subscription;
  bookingValuationSubscription: Subscription;

  roomPerson: RoomPersonsModel[];

  offer: OfferModel;
  guestForm: FormGroup;
  countries: CountryModel[];
  userLogged: UserModel;
  book_info: UserModel;
  formSelected: number = 1;
  search: SearchModel;
  hotel: HotelModel = null;
  numberOfPersons: number = 0;

  reservation: ReservationModel;

  disableInput: boolean = false;
  quoteID: string;
  offerID: string;

  display: boolean = false;
  quoteData: QuoteModel;
  checkVit: boolean = true;
  hotelData: HotelInfoModel;


  @ViewChild('checkedUser') checkedComponent: ElementRef;

  constructor(
    private _router: Router,
    private _commonService: CommonService,
    private _spinner: NgxSpinnerService,
    private _loginService: LoginService,
    private _userService: UserService,
    private _hotelService: HotelService,
    private _routeHandler: ActivatedRoute
  ) {
  }

  ngOnInit() {
    //
    clearNav();
    document.getElementById('mm1').classList.add('active');

    this.quoteID = this._routeHandler.snapshot.paramMap.get('quoteID');
    this.offerID = this._routeHandler.snapshot.paramMap.get('offerID');

    // ----------------------------- //
    this.subscriptionObject = new Subscription();
    this.subscriptionLogin = new Subscription();
    this.subscriptionPhones = new Subscription();
    this.subscriptionQuoteData = new Subscription();
    this.bookingValuationSubscription = new Subscription();
    this.reservation = new ReservationModel();
    this.userLogged = this._loginService.getUserInfo();
    this.listenLogin();
    this.getPhoneCodes();
    this.reservation = this._hotelService.getReservation();

    if (this.quoteID !== null && this.offerID !== null) {
      if (!this.reservation) {
        this.reservation = new ReservationModel();
        this.reservation.guests = [];
      }
      this.reservation.checkVit = false;
      this.getQuoteData();
    } else {
      this.asyncFillForm();
    }
  }

  asyncFillForm() {
    this.checkingData();
    if (this.userLogged) {
      this.formSelected = 2;
      this.disableInput = true;
      this.fillForm(this.userLogged);
    } else {
      this.formSelected = 1;
      this.disableInput = false;
      if (this.book_info) {
        this.fillForm(this.book_info);
      } else {
        this.fillForm(null);
      }
    }
    this.display = true;
  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
    if (this.subscriptionLogin) {
      this.subscriptionLogin.unsubscribe();
    }
    if (this.subscriptionPhones) {
      this.subscriptionPhones.unsubscribe();
    }
    if (this.subscriptionQuoteData) {
      this.subscriptionQuoteData.unsubscribe();
    }
  }

  listenLogin() {
    this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
      (data) => {
        switch (data) {
          case null:
          case undefined:
            this.userLogged = null;
            this.onSlider(1);
            break;
          default:
            this.userLogged = data;
            this.onSlider(2);
            break;
        }
      })
    );
  }

  getQuoteData() {
    this._spinner.show().then();
    this.subscriptionQuoteData.add(this._hotelService.getOffer(this.quoteID, this.offerID).subscribe(
      (data) => {
        this._spinner.hide().then();

        const offerData: OfferModel = {
          room_basis: data.data.quotation_offer.room_basis,
          room_type: data.data.quotation_offer.offer_name,
          room_type_array: [data.data.quotation_offer.room_type_array],
          hotel_search_code: data.data.quotation_offer.hotel_search_code,
          room_basis_description: data.data.quotation_offer.room_basis_description,
          availability: data.data.quotation_offer.availability,
          base_price: data.data.quotation_offer.price_provider,
          total_price: data.data.quotation_offer.price_for_client,
          currency: data.data.quotation_offer.currency.abbreviation,    // Missing Data
          cxl_deadline: data.data.quotation.cxl_deadline, // Missing Data
          preferred: data.data.quotation_offer.preferred,
          // tslint:disable-next-line:max-line-length
          remark: data.data.quotation_offer.remark,
          // tslint:disable-next-line:max-line-length
          special_offer: data.data.quotation_offer.special_offer,
          meal_plan: data.data.quotation_offer.meal_plan,
          market_id: data.data.quotation_offer.market_id,
          contract_id: data.data.quotation_offer.contract_id,
          rate_key: data.data.quotation_offer.rate_key,
          interface_info: data.data.quotation.interface_info,
          nigth_price: null,
          price_break_down: null,
        };

        const hotelDetail: HotelDetailModel = {
          id: data.data.hotel_data.id,
          hotel_code: data.data.hotel_data.hotel_code,
          hotel_name: data.data.hotel_data.hotel_name,
          stars: data.data.hotel_data.stars,
          thumbnail: data.data.hotel_data.thumbnail,
          address: data.data.hotel_data.address,
          city_code: data.data.hotel_data.city_code,
          phone: data.data.hotel_data.phone,
          fax: data.data.hotel_data.fax,
          description: data.data.hotel_data.description,
          descriptionArray: data.data.hotel_data.descriptionArray,
          hotel_facilities: data.data.hotel_data.hotel_facilities,
          room_facilities: data.data.hotel_data.room_facilities,
          room_count: data.data.hotel_data.room_count,
          pictures: data.data.hotel_data.pictures,
          trip_advisor: data.data.hotel_data.trip_advisor,
          latitude: data.data.hotel_data.latitude,
          longitude: data.data.hotel_data.longitude
        };

        const hotelData = {
          id: data.data.hotel_data.id,
          hotel_code: data.data.hotel_data.hotel_code,
          hotel_name: data.data.hotel_data.hotel_name,
          country_id: data.data.hotel_data.country.id,
          provider_id: data.data.quotation.provider_id,
          provider: {name: '', id: data.data.quotation.provider_id},
          stars: data.data.hotel_data.stars,
          location: data.data.hotel_data.location, // Missing Data
          location_code: 0, // Remove from Project Data
          thumbnail: data.data.hotel_data.thumbnail,
          low_cost: data.data.quotation_offer.price_for_client,
          low_cost_currency: data.data.quotation_offer.currency.abbreviation,  // Missing Data
          offers: [offerData],
          interface_info: data.data.quotation.interface_info,
          details_data: hotelDetail
        };

        const check_in: Date = new Date(data.data.quotation.check_in);
        const check_out: Date = new Date(data.data.quotation.check_out);

        const checkInStr = check_in.getFullYear() + '-' + (check_in.getMonth() + 1) + '-' + check_in.getDate();
        const checkInStrv2 = check_in.getDate() + '/' + (check_in.getMonth() + 1) + '/' + check_in.getFullYear();
        const checkOutStr = check_out.getFullYear() + '-' + (check_out.getMonth() + 1) + '-' + check_out.getDate();
        const checkOutStrv2 = check_out.getDate() + '/' + (check_out.getMonth() + 1) + '/' + check_out.getFullYear();

        const tempRooms = JSON.parse(data.data.quotation.rooms_persons);
        const newRooms = [];

        for (const room of tempRooms) {
          newRooms.push({adult: room.adults, child: room.childrens, ages: room.ages});
        }

        const searchData = {
          destination_id: data.data.hotel_data.city.id,
          check_in: checkInStr,
          check_out: checkOutStr,
          rooms_number: data.data.quotation.rooms,
          rooms_persons: newRooms,
          myDateRange: {
            beginDate: {
              year: check_in.getFullYear(),
              month: (check_in.getMonth() + 1),
              day: check_in.getDate()
            },
            beginJsDate: check_in.toUTCString(),
            endDate: {
              year: check_out.getFullYear(),
              month: (check_out.getMonth() + 1),
              day: check_in.getDate()
            },
            endJsDate: check_out.toUTCString(),
            formatted: checkInStrv2 + '-' + checkOutStrv2,
            beginEpoc: check_in.getUTCMilliseconds(),
            endEpoc: check_out.getUTCMilliseconds()
          },
          destination: data.data.hotel_data.city_code,
          adults: data.data.quotation.adult,
          childrens: data.data.quotation.children,
        };

        this.search = _.cloneDeep(searchData);
        this.hotel = _.cloneDeep(hotelData);

        this.reservation.search = _.cloneDeep(searchData);
        this.reservation.coming_from_search = true;
        this.reservation.offer = offerData;
        this.reservation.hotel = this.hotel;
        this.reservation.list_hotels = null;
        this.reservation.featuredHotel = true;
        this.reservation.actual_time = new Date().getTime();
        this.reservation.previousPage = 'home';

      }, (errMsg) => {
        this._spinner.hide().then();
        this.quoteError(errMsg.error.message);
      }, () => {
        this._hotelService.setReservation(this.reservation);
        this.asyncFillForm();
        this._spinner.hide().then();
      }
    ));
  }

  quoteError(errMsg) {
    Swal.fire({
      type: 'error',
      title: 'Error',
      text: errMsg,
      confirmButtonText: 'Back to Home',
    }).then((result) => {
      if (result.value) {
        this._router.navigate(['/home']).then();
      }
    });
  }

  getNumberOfGuests() {
    this.numberOfPersons = 0;
    const guests = this.reservation.guests;
    guests.map(
      (g) => {
        this.numberOfPersons += g.adults.length + g.childrens.length;
      }
    );
  }

  checkingData() {
    switch (this.reservation) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        if (!GeneralUtil.isListHotelsAlive(this.reservation.checkVit)) {
          this.searchExpireMessage();
          this._router.navigate(['/hotel.search']).then();
          return;
        }
        break;
    }

    this.search = this.reservation.search;

    switch (this.search) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.hotel = this.reservation.hotel;

    switch (this.hotel) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.offer = this.reservation.offer;

    switch (this.offer) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.roomPerson = this.reservation.search.rooms_persons;

    switch (this.roomPerson) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }
    this.getNumberOfGuests();

  }

  onSlider(id) {
    this.formSelected = id;
    if (this.formSelected === 1) {
      this.disableInput = false;
      this.enableInputs();
      this.fillForm(null);
    } else {
      if (this.userLogged) {
        this.disableInput = true;
        this.disableInputs();
        this.fillForm(this.userLogged);
      } else {
        this.goLoginOrRegister();
      }
    }
  }

  goLoginOrRegister() {
    Swal.fire({
      confirmButtonText: 'CANCEL',
      html: '<h3 class="title text-uc text-c4 tac mt-1 mb-2">Login or Register</h3>' +
        '<p>You are not logged in the system.</p><div class="cb mb-2"></div>' +
        '<div class="col col-45-1"><button id="register" class="btn btn-nor btn-3rd full-w">' +
        'REGISTER' +
        '</button></div>' +
        '<div class="col col-1-1 tac pt-2"><p>or</p></div>' +
        '<div class="col col-45-1"><button id="login" class="btn btn-nor btn-2nd full-w">' +
        'LOG IN' +
        '</button></div>' +
        '<div class="cb mb-3"></div>',
      customClass: {
        confirmButton: 'btn btn-sm btn-5th full-w'
      },
      onBeforeOpen: () => {
        const content = Swal.getContent();
        const $ = content.querySelector.bind(content);

        const login = $('#login');
        const register = $('#register');

        login.addEventListener('click', () => {
          openWindowWithoutEvent('w-login');
          Swal.close();
        });

        register.addEventListener('click', () => {
          this._router.navigate(['/register'], {queryParams: {redirect: '/hotel.booking'}}).then(() => Swal.close());
        });

      },
      onClose: () => {
        this.checkedComponent.nativeElement.click();
      }
    }).then();
  }

  getPhoneCodes() {
    this.subscriptionPhones.add(this._commonService.getPhoneCodes().subscribe(
      (data) => {
        this.countries = data.data;
      }
    ));
  }

  checkListOfPersons() {
    const guests = this._hotelService.getReservation().guests;
    if (!guests || guests.length === 0) {
      GeneralUtil.errorMessage('You have to fill the guests information, click \'Add Guests\' button to do that.');
      return false;
    }

    return true;
  }

  submitGuests() {
    const reservation = this._hotelService.getReservation();
    reservation.guests = [];
    const rooms = this.guestForm.get('rooms') as FormArray;
    for (let i = 0; i < rooms.controls.length; i++) {
      const guest_info: GuestModel = new GuestModel();
      guest_info.room_type = rooms.controls[i].get('name').value;
      guest_info.adults = [];
      guest_info.childrens = [];
      guest_info.room_number = i + 1;

      const adults = rooms.controls[i].get('adults') as FormArray;
      for (let j = 0; j < adults.controls.length; j++) {
        const adult: { title: string, name: string, last_name: string } = {
          title: adults.controls[j].get('title').value,
          name: adults.controls[j].get('name').value,
          last_name: adults.controls[j].get('last_name').value
        };
        guest_info.adults.push(adult);
      }


      // noinspection SpellCheckingInspection
      const children = rooms.controls[i].get('childrens') as FormArray;
      for (let j = 0; j < children.controls.length; j++) {
        const child: { title: string, name: string, last_name: string, age: number } = {
          title: 'MS',
          name: children.controls[j].get('name').value,
          last_name: children.controls[j].get('last_name').value,
          age: children.controls[j].get('age').value
        };
        guest_info.childrens.push(child);
      }

      reservation.guests.push(guest_info);
    }
    this._hotelService.setReservation(reservation);
  }

  // noinspection JSMethodCanBeStatic
  invalidFormMessage(errorMessage) {
    Swal.fire({
      title: 'Missing Information',
      html: errorMessage,
      type: 'error',
      allowOutsideClick: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then();
    // Forced
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

  findInvalidControls() {
    const errorMap = {rooms: 'Guests Info', name: 'Name', last_name: 'Last Name', email: 'Email', phone_number: 'Phone Number'};
    let errorMessage = 'Your Booking Details have this missing details:<br><div style="width: 50%; margin: auto"><p align="left">';
    const controls = this.guestForm.controls;
    for (const nameC in controls) {
      if (controls[nameC].invalid) {
        if (nameC === 'name') {
          errorMessage += '&#8226; <b>Main Guest\'s Name</b><br>';
        }
        if (nameC === 'last_name') {
          errorMessage += '&#8226; <b>Main Guest\'s Last Name</b><br>';
        }
        if (nameC === 'phone_number') {
          errorMessage += '&#8226; <b>Main Guest\'s Phone Number</b><br>';
        }
        if (nameC === 'rooms') {
          errorMessage += '&#8226; <b>Incomplete Additional Guests</b><br>';
        }
      }
    }
    errorMessage += '</p></div>';
    // errorMessage = errorMessage.slice(0, -2) + '!';
    this.invalidFormMessage(errorMessage);
  }

  checkDuplicateNames() {
    const reservation = this._hotelService.getReservation();
    const namesList = [];
    for (const room of reservation.guests) {
      for (const adults of room.adults) {
        namesList.push(adults.name.replace(/\s+/g, '') + adults.last_name.replace(/\s+/g, ''));
      }
      for (const childrens of room.childrens) {
        namesList.push(childrens.name.replace(/\s+/g, '') + childrens.last_name.replace(/\s+/g, ''));
      }
    }
    let counter = 0;
    for (const name of namesList) {
      counter = 0;
      for (const dup of namesList) {
        if (name === dup) {
          counter++;
        }
      }
      if (counter > 1) {
        return true;
      }
    }
    return false;
  }

  minLengthNames() {
    const reservation = this._hotelService.getReservation();
    for (const room of reservation.guests) {
      for (const adults of room.adults) {
        if (adults.name.length < 3 || adults.last_name.length < 3) {
          return true;
        }
      }
      for (const childrens of room.childrens) {
        if (childrens.name.length < 3 || childrens.last_name.length < 3) {
          return true;
        }
      }
    }
    return false;
  }

  checkValuation(userGuest) {
    if (this.reservation.hotel.provider.id === 1) {
      if (this.reservation.booking_valuation) {
        // Continue with booking
        this.submitProcess(userGuest);
        return true;
      }
      const params: RequestParams = {
        search: {
          hotel_search_code: this.reservation.offer.hotel_search_code,
          check_in: this.reservation.search.check_in
        }
      };
      this._spinner.show().then();
      this.bookingValuationSubscription.add(this._hotelService.checkGGTBookingValuation(params).subscribe(
        (data) => {
          this._spinner.hide().then();
          if (data.data.base_price) {
            this.reservation.offer.base_price = data.data.base_price;
            this.reservation.offer.total_price = data.data.total_price;
          }
          const cancelDate = data.data.CancellationDeadline.split('-');
          const cancelDateFormatted = cancelDate[2] + '-' + cancelDate[1] + '-' + cancelDate[0];
          this.reservation.offer.cxl_deadline = cancelDateFormatted;
          this.offer.cxl_deadline = cancelDateFormatted;
          this.reservation.offer.remark = data.data.Remarks;
          // Continue with booking
          this.reservation.booking_valuation = true;
          this.submitProcess(userGuest);
          return true;
        }, (err) => {
          this._spinner.hide().then();
          if (err.status !== 401) {
            if (err.error.message) {
              GeneralUtil.errorMessage(err.error.message);
              return false;
            }
            if (err.message) {
              GeneralUtil.errorMessage(err.message);
              return false;
            }
          }
        }
      ));
    } else {
      if (this.reservation.booking_valuation) {
        // Continue with booking
        this.submitProcess(userGuest);
        return true;
      }

      const rooms_persons = [];

      for (const room of this.reservation.search.rooms_persons) {
        rooms_persons.push({adults: room.adult, childrens: room.child, ages: room.ages});
      }

      const params: RequestParams = {
        search: {
          check_in: this.reservation.search.check_in,
          check_out: this.reservation.search.check_out,
          contract_id: this.reservation.offer.contract_id,
          hdo_id: this.reservation.hotel.hotel_code,
          interface_info: this.reservation.hotel.interface_info,
          market_id: this.reservation.offer.market_id,
          meal_plan: this.reservation.offer.meal_plan,
          rooms_persons: JSON.stringify(rooms_persons),
          room_type: this.reservation.offer.room_type,
          rate_key: this.reservation.offer.rate_key
        }
      };
      this._spinner.show().then();
      this.bookingValuationSubscription.add(this._hotelService.checkHDOBookingValuation(params).subscribe(
        (data) => {
          this._spinner.hide().then();
          if (data.data.base_price) {
            this.reservation.offer.base_price = data.data.base_price;
            this.reservation.offer.total_price = data.data.total_price;
          }
          const cancelDate = data.data.CancellationDeadline.split('-');
          const cancelDateFormatted = cancelDate[2] + '-' + cancelDate[1] + '-' + cancelDate[0];
          this.reservation.offer.cxl_deadline = cancelDateFormatted;
          this.offer.cxl_deadline = cancelDateFormatted;
          // Continue with booking
          this.reservation.booking_valuation = true;
          this.submitProcess(userGuest);
          return true;
        }, (err) => {
          this._spinner.hide().then();
          if (err.status !== 401) {
            if (err.error.message) {
              GeneralUtil.errorMessage(err.error.message);
              return false;
            }
            if (err.message) {
              GeneralUtil.errorMessage(err.message);
              return false;
            }
          }
        }
      ));
    }
  }

  submitProcess(userGuest) {
    this._spinner.show().then();
    this.subscriptionObject.add(this._userService.registerGuest(userGuest).subscribe(
      (data) => {
        const reservation = this._hotelService.getReservation();
        reservation.book_info = data.data;
        reservation.hotel = this.hotel;
        reservation.offer = this.offer;
        this._hotelService.setReservation(reservation);
        this._hotelService._triggerOfferSearch = true;
        this._hotelService._triggerOfferChange = true;
        this._hotelService._triggerPaymentWindow = true;
        this._spinner.hide().then();
        this._router.navigate(['/hotel.payment']).then();
      }, (error) => {
        this._spinner.hide().then();
        GeneralUtil.errorMessage(error.error.message);
      })
    );
  }

  onSubmit() {
    if (this.guestForm.valid) {
      if (!GeneralUtil.validateEmail(this.guestForm.get('email').value)) {
        GeneralUtil.errorMessage('Invalid Email Address!');
        return;
      }

      this.submitGuests();
      if (this.checkDuplicateNames()) {
        GeneralUtil.errorMessage('Duplicate Guests!');
        return;
      }
      if (this.minLengthNames()) {
        GeneralUtil.errorMessage('All guests requires a minimum length of 3 characters for Name and/or Last Name!');
        return;
      }
      if (!this.checkListOfPersons()) {
        return;
      }
      const userGuest = {
        email: this.guestForm.get('email').value,
        last_name: this.guestForm.get('name').value,
        name: this.guestForm.get('last_name').value,
        phone_number: this.guestForm.get('phone_number').get('country').value + '-'
          + this.guestForm.get('phone_number').get('area').value + '-'
          + this.guestForm.get('phone_number').get('number').value,
        user_info: UserInfoModel
      };

      this.checkValuation(userGuest);

    } else {
      this.findInvalidControls();
      GeneralUtil.validateAllFormFields(this.guestForm);
    }
  }


  // Mine Old

  quickArray(length) {
    const quick = [];
    for (let i = 0; i < length; i++) {
      quick.push(i);
    }
    return quick;
  }

  disableInputs() {
    this.guestForm.get('name').disable();
    this.guestForm.get('last_name').disable();
    this.guestForm.get('email').disable();
    this.guestForm.get('phone_number').disable();
  }

  enableInputs() {
    this.guestForm.get('name').enable();
    this.guestForm.get('last_name').enable();
    this.guestForm.get('email').enable();
    this.guestForm.get('phone_number').enable();
  }

  fillForm(user: UserModel) {
    if (this.formSelected === 1) {
      user = null;
    }
    let country = null;
    let area = null;
    let number = null;
    if (user) {
      const phoneNumberArray = user.phone_number.split('-');
      country = phoneNumberArray[0];
      area = phoneNumberArray[1];
      number = phoneNumberArray[2];
    }

    let rooms: FormGroup[] = [];


    rooms = this.onCreate(rooms);

    this.guestForm = new FormGroup({
      rooms: new FormArray(rooms),
      'name': new FormControl(user ? user.name : null, [Validators.required]),
      'last_name': new FormControl(user ? user.last_name : null, [Validators.required]),
      'email': new FormControl(user ? user.email : null, [Validators.required]),
      'phone_number': new FormGroup({
        'country': new FormControl(country, [Validators.required]),
        'area': new FormControl(area, [Validators.required, INTEGER_VALIDATOR, Validators.minLength(1), Validators.maxLength(3)]),
        'number': new FormControl(number, [Validators.required, INTEGER_VALIDATOR, Validators.minLength(5), Validators.maxLength(7)]),
      }),
    });

    if (this.disableInput === true) {
      this.disableInputs();
    }

  }

  onCreate(rooms) {
    for (let i = 0; i < this.roomPerson.length; i++) {
      const adults: FormGroup[] = [];
      for (let j = 0; j < this.roomPerson[i].adult; j++) {
        adults.push(new FormGroup({
          'title': new FormControl(null, Validators.required),
          'name': new FormControl(null, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
          'last_name': new FormControl(null, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
        }));
      }

      const children: FormGroup[] = [];

      for (let j = 0; j < this.roomPerson[i].child; j++) {
        children.push(new FormGroup({
          'title': new FormControl('MS', Validators.required),
          'name': new FormControl(null, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
          'last_name': new FormControl(null, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
          'age': new FormControl(this.roomPerson[i].ages[j], Validators.required),
        }));
      }

      // noinspection SpellCheckingInspection
      rooms.push(new FormGroup({
        'name': new FormControl(this.offer.room_type_array.length === 1 ? this.offer.room_type : this.offer.room_type_array[i],
          Validators.required),
        'adult_number': new FormControl(this.roomPerson[i].adult, Validators.required),
        'child_number': new FormControl(this.roomPerson[i].child, Validators.required),
        'adults': new FormArray(adults),
        'childrens': new FormArray(children)
      }));

    }

    return rooms;
  }

  // noinspection JSUnusedGlobalSymbols
  searchExpireMessage() {
    Swal.fire({
      title: 'Expired search',
      text: 'Your search has an expiration time of 20 min, these have already passed. Try again',
      type: 'error',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'New Search'
    }).then((result) => {
      if (result.value) {
        this._router.navigate(['/hotel.search']).then();
      }
    });
    // Forced
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

  // Useless

  // noinspection JSUnusedGlobalSymbols
  onBook(event) {
    event.stopPropagation();
    //
    this._router.navigate(['/hotel.payment']).then();
  }

}
