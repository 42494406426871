import {Injectable} from '@angular/core';

import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {LoginService} from '../../services/login.service';
import {GeneralUtil} from './general.util';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private _spinnerService: NgxSpinnerService, private _loginService: LoginService, private _router: Router) {
  }

  addToken(req: HttpRequest<any>, token: any): HttpRequest<any> {
    if (token) {
      return req.clone({setHeaders: {Authorization: 'Bearer ' + token.access_token}});
    } else {
      return req.clone({setHeaders: {}});
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(req, this._loginService.getToken())).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          this.procesandoErroresHttp(error, req, next);
        }
        this._spinnerService.hide();
        return throwError(error);
      })
    );
  }

  procesandoErroresHttp(response: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler) {
    var errorMessages = '';
    switch (response.status) {
      case 422:
        var error = response.error;
        if (error['errors']) {
          for (var i in error['errors']) {
            errorMessages += error['errors'][i] + '\n';
          }
        }
        if (error['success'] == false) {
          errorMessages = error['message'];
        }
        break;

      case 500:
        var error = response.error;
        if (error['type']) {
          errorMessages = error['message'];
        }
        break;
      case 401:
        var error = response.error;
        if (error['message'] = 'The refresh token is invalid' && error['hint'] == 'Token has expired') {
          localStorage.clear();
          this._loginService._logged.next(false);
          this._router.navigate(['/']);
        } else {
          return this.refreshingTokend(req, next);
          //Hacer pericion de refresh
        }
        break;
      case 403:
        this._router.navigate(['/login']);
        localStorage.clear();
        break;
      default:
        break;
    }
    if (response.status != 401) {
      this.openModal(errorMessages);
    }
  }

  openModal(errorMessage: string) {
    GeneralUtil.errorMessage(errorMessage);
  }


  refreshingTokend(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      return this._loginService.doRefresh().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.tokenSubject.next(this._loginService.getToken());
            return next.handle(this.addToken(req, this._loginService.getToken()));
          }

          // If we don't get a new token, we are in trouble so logout.
          return this.logoutUser();
        }),
        catchError(error => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.logoutUser();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      ).subscribe();

    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req, token));
        })
      );

    }
  }

  logoutUser() {
    // Route to the login page (implementation up to you)
    this.isRefreshingToken = false;
    localStorage.clear();
    this._loginService._logged.next(false);
    this._router.navigate(['/']);
    return throwError("");
  }
}
