import {Component, OnInit} from '@angular/core';

declare function closeWindow(event: any, wID: any);

@Component({
  selector: 'app-w-map',
  templateUrl: './w-map.component.html',
  styleUrls: ['./w-map.component.css']
})
export class WMapComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  onCloseWindow(event, wID) {
    event.stopPropagation();
    //
    closeWindow(event, wID);
  }

}
