import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appLetterSpacesOnly]'
})
export class LetterSpacesOnlyDirective {

  constructor(private _el: ElementRef) {}


  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this._el.nativeElement.value;

    if (initialValue.length === 1) {
      this._el.nativeElement.value = initialValue.replace(/[^a-zA-Z]*/g, '');
      if (initialValue !== this._el.nativeElement.value) {
        event.stopPropagation();
      }
    } else {
      this._el.nativeElement.value = initialValue.replace(/[^a-zA-Z\s]*/g, '');
      if (initialValue !== this._el.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }
}
