import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CountryModel, HotelModel, OfferModel, ReservationModel, SearchModel, UserInfoModel, UserModel} from '../../models';
import {Subscription} from 'rxjs';
import {ElementOptions, ElementsOptions, StripeService} from '@nomadreservations/ngx-stripe';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {HotelService} from '../../services/hotel.service';
import {CommonService} from '../../services/common.service';
import {LoginService} from '../../services/login.service';
import Swal from 'sweetalert2';
import {GeneralUtil} from '../../core/util/general.util';
import {UserService} from '../../services/user.service';
import {StoreUtil} from '../../core/util/store.util';

declare function openWindowWithoutEvent(wID: any);

declare function clearNav(): any;

@Component({
  selector: 'app-payment-hotel',
  templateUrl: './payment-hotel.component.html',
  styleUrls: ['./payment-hotel.component.css']
})
export class PaymentHotelComponent implements OnInit, OnDestroy {

  // viewMode1 = 'not_registered';
  // stripeKey = '';

  error: any;
  complete = false;
  element: Element;
  countries: CountryModel[];
  subscriptionObject: Subscription;
  subscriptionLogin: Subscription;
  subscriptionClient: Subscription;
  formSelected: number = 1;
  userLogged: UserModel;
  hotel: HotelModel;
  @ViewChild('cardHolder') cardHolder: ElementRef;
  @ViewChild('checkedUser') checkedComponent: ElementRef;

  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#81c0bA',
        color: '#283d4a',
        lineHeight: '40px',
        fontWeight: 400,
        fontFamily: '"Source Sans Pro","Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#aaaaaa'
        }
      },
      invalid: {
        color: '#ff005d',
        iconColor: '#ff005d'
      }
    }
  };


  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  stripeTest: FormGroup;

  disableInput: boolean = false;

  reservation: ReservationModel;
  search: SearchModel;
  bookInfo: UserModel;
  offer: OfferModel;

  constructor(
    private _router: Router,
    private _hotelService: HotelService,
    private _stripeService: StripeService,
    private _commonService: CommonService,
    private _loginService: LoginService,
    private _userService: UserService,
    private _spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    this.subscriptionObject = new Subscription();
    this.subscriptionLogin = new Subscription();
    this.subscriptionClient = new Subscription();
    this.userLogged = this._loginService.getUserInfo();

    this.checkingData();
    this.listenLogin();

    this.getCountries();

    if (this.userLogged && this.userLogged.user_info) {
      this.formSelected = 2;
      this.disableInput = true;
      this.fillForm(this.userLogged);
    } else {
      this.formSelected = 1;
      this.disableInput = false;
      if (this.bookInfo) {
        this.fillForm(this.bookInfo);
      } else {
        this.fillForm(null);
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
    if (this.subscriptionLogin) {
      this.subscriptionLogin.unsubscribe();
    }
  }

  listenLogin() {
    this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
      (data) => {
        switch (data) {
          case null:
          case undefined:
            this.userLogged = null;
            this.onSlider(1);
            break;
          default:
            this.userLogged = data;
            this.onSlider(2);
            break;
        }
      })
    );
  }

  checkingData() {
    this.reservation = this._hotelService.getReservation();

    switch (this.reservation) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        if (!GeneralUtil.isListHotelsAlive(this.reservation.checkVit)) {
          this.searchExpireMessage();
          this._router.navigate(['/hotel.search']).then();
          return;
        }
        break;
    }

    this.search = this.reservation.search;

    switch (this.search) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.hotel = this.reservation.hotel;

    switch (this.hotel) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.offer = this.reservation.offer;

    switch (this.offer) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.bookInfo = this.reservation.book_info;

    switch (this.bookInfo) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

  }

  cardUpdated(result) {
    this.element = result.element;
    this.complete = result.card.complete;
    this.error = undefined;
  }

  disableInputs() {
    this.stripeTest.get('address_line1').disable();
    this.stripeTest.get('address_line2').disable();
    this.stripeTest.get('address_ext_number').disable();
    this.stripeTest.get('address_int_number').disable();
    this.stripeTest.get('country_id').disable();
    this.stripeTest.get('address_state').disable();
    this.stripeTest.get('address_city').disable();
    this.stripeTest.get('address_zip').disable();
  }

  enableInputs() {
    this.stripeTest.get('address_line1').enable();
    this.stripeTest.get('address_line2').enable();
    this.stripeTest.get('address_ext_number').enable();
    this.stripeTest.get('address_int_number').enable();
    this.stripeTest.get('country_id').enable();
    this.stripeTest.get('address_state').enable();
    this.stripeTest.get('address_city').enable();
    this.stripeTest.get('address_zip').enable();
  }


  // noinspection JSMethodCanBeStatic
  getNights(search) {
    const date_1: any = new Date(search.check_in);
    const date_2: any = new Date(search.check_out);

    const day_as_milliseconds = 86400000;
    const diff_in_milliseconds = date_2 - date_1;
    return diff_in_milliseconds / day_as_milliseconds;
  }

  findCountryById(id) {
    return this.countries.filter((data) => data.id === Number(id));
  }

  // noinspection JSMethodCanBeStatic
  invalidFormMessage(errorMessage) {
    Swal.fire({
      title: 'Missing Information',
      html: errorMessage,
      type: 'error',
      allowOutsideClick: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then();
    // Forced
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

  findInvalidControls() {
    const errorMap = {
      name: 'Name ',
      address_line1: 'Address Line 1',
      address_city: 'City',
      address_state: 'State',
      address_zip: 'Zip Code',
      country_id: 'Country'
    };
    let errorMessage = 'Your Booking Details have this missing details:<br><div style="width: 50%; margin: auto"><p align="left">';
    const controls = this.stripeTest.controls;
    if (!this.complete) {
      errorMessage += '&#8226; <b>Card Details</b><br>';
    }
    for (const nameC in controls) {
      if (controls[nameC].invalid) {
        if (nameC === 'name') {
          errorMessage += '&#8226; <b>Card Owner\'s Name</b><br>';
        }
        if (nameC === 'address_line1') {
          errorMessage += '&#8226; <b>Billing Address - Address Line 1</b><br>';
        }
        if (nameC === 'country_id') {
          errorMessage += '&#8226; <b>Billing Address - Country</b><br>';
        }
        if (nameC === 'address_state') {
          errorMessage += '&#8226; <b>Billing Address - State</b><br>';
        }
        if (nameC === 'address_city') {
          errorMessage += '&#8226; <b>Billing Address - City</b><br>';
        }
        if (nameC === 'address_zip') {
          errorMessage += '&#8226; <b>Billing Address - Zip Code</b><br>';
        }
      }
    }
    errorMessage += '</p></div>';
    this.invalidFormMessage(errorMessage);
  }

  zipCodeError(country = '', length = 0, format = '', generic = false) {
    let message;
    if (!generic) {
      message = country + ' needs a zip-code of ' + length + ' with a format of: ' + format;
    } else {
      message = 'Zip-Code must have a length 6 characters';
    }
    Swal.fire({
      title: 'Error!',
      text: message,
      type: 'error',
      confirmButtonText: 'Ok'
    });
  }

  buy() {
    if (this.stripeTest.valid) {
      const selectedCountryID = parseInt(this.stripeTest.get('country_id').value, 10);
      const selectedZipCode = this.stripeTest.get('address_zip').value;
      const verifiedCountries = [200, 36, 122, 91];

      if (selectedCountryID === 200) {
        if (!(selectedZipCode.match(/^[0-9]{5}$/g))) {
          this.zipCodeError('United States', 5, '00000');
          return;
        }
      }

      if (selectedCountryID === 91) {
        if (!(selectedZipCode.match(/^[0-9]{5}$/g))) {
          this.zipCodeError('Israel', 5, '00000');
          return;
        }
      }

      if (selectedCountryID === 122) {
        if (!(selectedZipCode.match(/^[0-9]{5}$/g))) {
          this.zipCodeError('Mexico', 5, '00000');
          return;
        }
      }

      if (selectedCountryID === 36) {
        if (!(selectedZipCode.match(/^[a-zA-Z][0-9][a-zA-Z]\s[0-9][a-zA-Z][0-9]$/g))) {
          this.zipCodeError('Canada', 6, 'A1A 1A1');
          return;
        }
      }

      if (verifiedCountries.includes(selectedCountryID) === false) {
        if (!(selectedZipCode.match(/^[a-zA-Z0-9]{6}$/g))) {
          this.zipCodeError('', 0, '0', true);
          return;
        }
      }

      this._spinner.show().catch((err) => console.error(err));
      const tempCountry = this.findCountryById(this.stripeTest.get('country_id').value);
      // @ts-ignore
      this._stripeService.createToken(this.element, {
        name: this.stripeTest.get('name').value,
        address_line1: this.stripeTest.get('address_line1').value,
        address_city: this.stripeTest.get('address_city').value,
        address_state: this.stripeTest.get('address_state').value,
        address_zip: this.stripeTest.get('address_zip').value,
        address_country: tempCountry['iso_code'],
      }).subscribe(result => {

        const user_info = {
          address1: this.stripeTest.get('address_line1').value,
          address2: this.stripeTest.get('address_line2').value,
          city: this.stripeTest.get('address_city').value,
          state: this.stripeTest.get('address_state').value,
          zip_code: this.stripeTest.get('address_zip').value,
          country_id: Number(this.stripeTest.get('country_id').value),
          int_number: this.stripeTest.get('address_int_number').value,
          ext_number: this.stripeTest.get('address_ext_number').value,
        };

        const rooms_persons = [];

        for (const room of this.reservation.search.rooms_persons) {
          rooms_persons.push({adults: room.adult, childrens: room.child, ages: room.ages});
        }

        const arrPhone = this.reservation.book_info.phone_number.split('-');

        let reservationRequest = null;

        if (this.reservation.hotel.provider.id === 1) {
          reservationRequest = {
            provider_id: this.reservation.hotel.provider.id,
            base_price: this.reservation.offer.base_price,

            token: result.token.id,
            last_four: result.token.card.last4,
            brand: result.token.card.brand,
            user_info: user_info,
            client: {
              first_name: this.reservation.book_info.name,
              last_name: this.reservation.book_info.last_name,
              email: this.reservation.book_info.email,
              user_id: this.reservation.book_info.id,
              country: arrPhone[0],
              area: arrPhone[1],
              number: arrPhone[2]
            },

            ggt_id: this.reservation.hotel.hotel_code,
            hotel_search_code: this.reservation.offer.hotel_search_code,
            nights: this.getNights(this.reservation.search),
            check_in: this.reservation.search.check_in,
            check_out: this.reservation.search.check_out,
            currency_id: this.reservation.offer.currency,
            city_id: this.reservation.search.destination_id,
            reservation_rooms: this.reservation.guests,
            rooms_persons: JSON.stringify(rooms_persons),
            total_price: this.reservation.offer.total_price,
            cancel_dead_line: this.reservation.offer.cxl_deadline
          };
        } else {
          const doAccommodation = [];

          for (let i = 0; i < this.reservation.guests.length; i++) {
            // noinspection SpellCheckingInspection
            doAccommodation.push(
              {
                'currency_id': this.reservation.offer.currency,
                'base_price': this.reservation.offer.base_price,
                'total_price': this.reservation.offer.total_price,
                'rate_key': this.reservation.offer.rate_key,
                'adults': this.reservation.guests[i].adults,
                'childrens': this.reservation.guests[i].childrens,
                'room_type': this.reservation.guests[i].room_type
              }
            );
          }

          const cancelDate = this.reservation.offer.cxl_deadline.split('-');

          const cancelDateFormatted = cancelDate[2] + '-' + cancelDate[1] + '-' + cancelDate[0];

          reservationRequest = {
            provider_id: this.reservation.hotel.provider.id,
            currency_id: 'USD',
            base_price: this.reservation.offer.base_price,
            total_price: this.reservation.offer.total_price,

            hdo_id: this.reservation.hotel.hotel_code,
            room_type: this.reservation.offer.room_type,
            meal_plan: this.reservation.offer.meal_plan,
            market_id: this.reservation.offer.market_id,
            contract_id: this.reservation.offer.contract_id,
            interface_info: this.reservation.hotel.interface_info,
            check_in: this.reservation.search.check_in,
            check_out: this.reservation.search.check_out,

            reservation_rooms: doAccommodation,
            rooms_persons: JSON.stringify(rooms_persons),

            token: result.token.id,
            last_four: result.token.card.last4,
            brand: result.token.card.brand,
            user_info: user_info,
            client: {
              first_name: this.reservation.book_info.name,
              last_name: this.reservation.book_info.last_name,
              email: this.reservation.book_info.email,
              user_id: this.reservation.book_info.id,
              country: arrPhone[0],
              area: arrPhone[1],
              number: arrPhone[2]
            },
            hotel_search_code: null,
            nights: this.getNights(this.reservation.search),
            city_id: this.reservation.search.destination_id,
            cancel_dead_line: cancelDateFormatted
          };
        }

        const userGuest = {
          email: this.reservation.book_info.email,
          last_name: this.reservation.book_info.last_name,
          name: this.reservation.book_info.name,
          phone_number: this.reservation.book_info.phone_number,
          user_info: user_info
        };

        if (this.userLogged) {
          this.userLogged.user_info = new UserInfoModel();
          this.userLogged.user_info.address1 = user_info.address1;
          this.userLogged.user_info.address2 = user_info.address2;
          this.userLogged.user_info.ext_number = user_info.ext_number;
          this.userLogged.user_info.int_number = user_info.int_number;
          this.userLogged.user_info.country_id = user_info.country_id;
          this.userLogged.user_info.zip_code = user_info.zip_code;
          this.userLogged.user_info.state = user_info.state;
          this.userLogged.user_info.city = user_info.city;
          StoreUtil.objectToStore('user', this.userLogged);
        }

        this.subscriptionClient.add(this._userService.registerGuest(userGuest).subscribe(
          () => {
            this._hotelService.makeAReservation(reservationRequest).subscribe(
              (data) => {
                this._spinner.hide().then();
                HotelService.setReservationResult(data.object);
                this._hotelService.resetReservation();
                this._router.navigate(['/confirmation']).then();
              }, (error) => {
                this._spinner.hide().then();
                if (error.error.message) {
                  GeneralUtil.errorMessage(error.error.message);
                  return;
                }
                if (error.message) {
                  GeneralUtil.errorMessage(error.message);
                  return;
                }
              }
            );
          }, (error) => {
            this._spinner.hide().then();
            if (error.error.message) {
              GeneralUtil.errorMessage(error.error.message);
              return;
            }
            if (error.message) {
              GeneralUtil.errorMessage(error.message);
              return;
            }
          }
        ));
      }, (err) => {
        this._spinner.hide().then();
        switch (err.type) {
          case 'StripeCardError':
            // A declined card error
            GeneralUtil.errorMessage(err.message);
            break;
          case 'StripeInvalidRequestError':
            // Invalid parameters were supplied to Stripe's API
            GeneralUtil.errorMessage(err.message);
            break;
          case 'StripeAPIError':
            // An error occurred internally with Stripe's API
            GeneralUtil.errorMessage(err.message);
            break;
          case 'StripeConnectionError':
            // Some kind of error occurred during the HTTPS communication
            GeneralUtil.errorMessage(err.message);
            break;
          case 'StripeAuthenticationError':
            // You probably used an incorrect API key
            GeneralUtil.errorMessage(err.message);
            break;
          case 'StripeRateLimitError':
            // Too many requests hit the API too quickly
            GeneralUtil.errorMessage(err.message);
            break;
        }
      });
    } else {
      this.findInvalidControls();
      GeneralUtil.validateAllFormFields(this.stripeTest);
    }
  }

  fillForm(user) {
    if (this.formSelected === 1) {
      user = null;
    }
    let cardOlderName = '';
    if (this.cardHolder) {
      cardOlderName = this.cardHolder.nativeElement.value;
    }

    this.stripeTest = new FormGroup({
      'name': new FormControl(cardOlderName, [Validators.required]),
      'address_line1': new FormControl(user ? (user.user_info ? user.user_info.address1 : null) : null, [Validators.required]),
      'address_line2': new FormControl(user ? (user.user_info ? user.user_info.address2 : null) : null),
      'address_city': new FormControl(user ? (user.user_info ? user.user_info.city : null) : null, [Validators.required]),
      'address_state': new FormControl(user ? (user.user_info ? user.user_info.state : null) : null, [Validators.required]),
      'address_zip': new FormControl(user ? (user.user_info ? user.user_info.zip_code : null) : null, [Validators.required]),
      'country_id': new FormControl(user ? (user.user_info ? user.user_info.country_id : null) : null, [Validators.required]),
      'address_int_number': new FormControl(user ? (user.user_info ? user.user_info.int_number : null) : null),
      'address_ext_number': new FormControl(user ? (user.user_info ? user.user_info.ext_number : null) : null),
    });

    if (this.disableInput === true) {
      this.disableInputs();
    }
  }

  getCountries() {
    this.subscriptionObject.add(this._commonService.getCountries().subscribe(
      (data) => {
        this.countries = data.data;
      }
    ));
  }

  // noinspection DuplicatedCode
  onSlider(id) {
    this.formSelected = id;
    if (this.formSelected === 1) {
      this.disableInput = false;
      this.enableInputs();
      this.fillForm(null);
    } else {
      if (this.userLogged) {
        if (this.userLogged.user_info) {
          this.disableInput = true;
          this.disableInputs();
          this.fillForm(this.userLogged);
          return;
        } else {
          this.onSlider(1);
          this.checkedComponent.nativeElement.click();
          GeneralUtil.infoMessage2('This user have no registered address!');
          return;
        }
      } else {
        this.goLoginOrRegister();
      }
    }
  }

  goLoginOrRegister() {
    Swal.fire({
      confirmButtonText: 'CANCEL',
      html: '<h3 class="title text-uc text-c4 tac mt-1 mb-2">Login or Register</h3>' +
        '<p>You are not logged in the system.</p><div class="cb mb-2"></div>' +
        '<div class="col col-45-1"><button id="register" class="btn btn-nor btn-3rd full-w">' +
        'REGISTER' +
        '</button></div>' +
        '<div class="col col-1-1 tac pt-2"><p>or</p></div>' +
        '<div class="col col-45-1"><button id="login" class="btn btn-nor btn-2nd full-w">' +
        'LOG IN' +
        '</button></div>' +
        '<div class="cb mb-3"></div>',
      customClass: {
        confirmButton: 'btn btn-sm btn-5th full-w'
      },
      onBeforeOpen: () => {
        const content = Swal.getContent();
        const $ = content.querySelector.bind(content);

        const login = $('#login');
        const register = $('#register');

        login.addEventListener('click', () => {
          openWindowWithoutEvent('w-login');
          Swal.close();
        });

        register.addEventListener('click', () => {
          this._router.navigate(['/register'], {queryParams: {redirect: '/hotel.payment'}}).then(() => Swal.close());
        });

      },
      onClose: () => {
        this.checkedComponent.nativeElement.click();
      }
    }).then();
  }

  // noinspection JSUnusedGlobalSymbols
  searchExpireMessage() {
    Swal.fire({
      title: 'Expired search',
      text: 'Your search has an expiration time of 20 min, these have already passed. Try again',
      type: 'error',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'New Search'
    }).then((result) => {
      if (result.value) {
        this._router.navigate(['/hotel.search']).then();
      }
    });
    // Forced
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

  // noinspection JSUnusedGlobalSymbols
  onPay(event) {
    event.stopPropagation();
    //
    clearNav();
    this._router.navigate(['/confirmation']).then();
  }

}
