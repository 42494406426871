import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './home/home.component';
import {RegisterComponent} from './register/register.component';

import {HotelSearchComponent} from './hotel/hotel-search/hotel-search.component';
import {HotelsComponent} from './hotel/hotels/hotels.component';
import {HotelComponent} from './hotel/hotel/hotel.component';
import {BookingHotelComponent} from './booking/booking-hotel/booking-hotel.component';

import {NauticalComponent} from './nautica/nautica/nautical.component';
import {BookingNauticalComponent} from './booking/booking-nautica/booking-nautical.component';

import {MyBookingsComponent} from './my-bookings/my-bookings.component';
import {PaymentHotelComponent} from './payment/payment-hotel/payment-hotel.component';

import {PaymentConfirmationComponent} from './payment/payment-confirmation/payment-confirmation.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},

  {path: 'home', component: HomeComponent},
  {path: 'register', component: RegisterComponent},

  {path: 'hotel.search', component: HotelSearchComponent},
  {path: 'hotels', component: HotelsComponent},
  {path: 'hotel', component: HotelComponent},

  {path: 'hotel.booking', component: BookingHotelComponent},
  {path: 'hotel/quotations/:quoteID/:offerID', component: BookingHotelComponent},
  {path: 'hotel.payment', component: PaymentHotelComponent},

  {path: 'nautica', component: NauticalComponent},
  {path: 'nautica.booking', component: BookingNauticalComponent},

  {path: 'confirmation', component: PaymentConfirmationComponent},

  {path: 'my.bookings', component: MyBookingsComponent},

  {path: '**', redirectTo: 'home', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}
