import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {INTEGER_VALIDATOR} from '../core/util/form-validators.util';
import {GeneralUtil} from '../core/util/general.util';
import {CountryModel, UserModel} from '../models';
import {Subscription} from 'rxjs';
import {CommonService} from '../services/common.service';
import {UserService} from '../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';

declare function openWindow(event: any, wID: any);

declare function openWindowWithoutEvent(wID: any);

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {

  registerForm: FormGroup;
  countries: CountryModel[];
  subscriptionObject: Subscription;

  redirect: string;

  constructor(
    private _router: Router,
    private _commonService: CommonService,
    private _userService: UserService,
    private _spinner: NgxSpinnerService,
    private _routeHandler: ActivatedRoute) {
  }


  ngOnInit() {
    this._routeHandler.queryParams.subscribe(params => {
      if (params['redirect']) {
        this.redirect = params['redirect'];
      } else {
        this.redirect = '/home';
      }
    });

    this.subscriptionObject = new Subscription();
    this.fillForm();
    this.getPhoneCodes();
  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

  getPhoneCodes() {
    this.subscriptionObject.add(this._commonService.getPhoneCodes().subscribe(
      (data) => {
        this.countries = data.data;
      }
    ));
  }

  onSubmit() {
    if (this.registerForm.valid) {

      if (this.registerForm.get('password').value !== this.registerForm.get('password_confirmation').value) {
        GeneralUtil.errorMessage('Passwords are different!');
        return;
      }

      if (!GeneralUtil.validateEmail(this.registerForm.get('email').value)) {
        GeneralUtil.errorMessage('Invalid Email Address!');
        return;
      }

      const ticket = new UserModel();
      const model = Object.assign({}, ticket, this.registerForm.value);
      model.phone_number = model.phone_number.country + '-' + model.phone_number.area + '-' + model.phone_number.number;
      this._spinner.show().then();
      this._userService.register(model).subscribe(
        (data) => {
          this._spinner.hide().then();
          openWindowWithoutEvent('w-login');
          this._router.navigate([this.redirect]).then();
          GeneralUtil.successMessage(data.message, 4000);
        }, (err) => {
          this._spinner.hide().then();
          GeneralUtil.errorMessage(err.error.message);
        }
      );
    } else {
      GeneralUtil.validateAllFormFields(this.registerForm);
    }
  }


  fillForm() {
    this.registerForm = new FormGroup({
      'name': new FormControl(null, [Validators.required]),
      'last_name': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, Validators.required),
      'password_confirmation': new FormControl(null, Validators.required),
      'phone_number': new FormGroup({
        'country': new FormControl(null, [Validators.required]),
        'area': new FormControl(null, [Validators.required, INTEGER_VALIDATOR]),
        'number': new FormControl(null, [Validators.required, INTEGER_VALIDATOR]),
      }),
    });
  }


  onLogIn(event, wID) {
    //
    this._router.navigate([this.redirect]).then();
    openWindow(event, wID);
  }

}
