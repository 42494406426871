import {Component, DoCheck, OnDestroy, OnInit} from '@angular/core';
import {DetailsDataModel, HotelInfoModel, HotelModel, OfferModel, ReservationModel, SearchModel} from '../../models';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {HotelService} from '../../services/hotel.service';
import {Subscription} from 'rxjs';
import {RequestParams} from '../../core/api/api.model';

declare function closeWindowWithoutEvent(wID: any);

@Component({
  selector: 'app-w-offer-detail',
  templateUrl: './w-offer-detail.component.html',
  styleUrls: ['./w-offer-detail.component.css']
})
export class WOfferDetailComponent implements OnInit, OnDestroy {

  subscriptionObject: Subscription;
  hotelSelected: HotelModel;
  hotelDates: string[];
  offers: OfferModel[];
  status: boolean = false;

  constructor(
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _hotelService: HotelService) {
  }

  ngOnInit() {
    this.subscriptionObject = new Subscription();
    this.listenOpenWindow();
  }

  ngOnDestroy(): void {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }


  listenOpenWindow() {
    this.subscriptionObject.add(this._hotelService._fireOpenOfferWindow.subscribe(
      (data) => {
        this.hotelSelected = data.hotel;
        if (!this.hotelSelected.details_data) {
          this.getHotelDetails();
        }
        this.offers = data.hotel.offers;
        this.hotelDates = data.dateRange;
        this.status = true;
      }
    ));

  }

  getHotelDetails() {
    const params: RequestParams = {

      params: {
        id: this.hotelSelected.id
      },
      search: {
        id_search_code: this.hotelSelected.offers[0].hotel_search_code
      }
    };
    this.spinner.show().then();
    this._hotelService.getHotelDetails(params).subscribe(
      (data) => {
        this.spinner.hide().then();
        this.hotelSelected.details_data = data.data;
      }
    );
  }

  onCloseWindow(wID) {
    //
    closeWindowWithoutEvent(wID);
  }

}
