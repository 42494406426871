import {GenericModel} from '.';

export class SearchModel {
  destination_id: number = 0;
  check_in: string = '';
  check_out: string = '';
  rooms_number: number = 1;
  rooms_persons: RoomPersonsModel[] = [{adult: 1, child: 0, ages: []}];
  myDateRange: any;
  destination: GenericModel;
  adults: number;
  childrens: number;
}

export class RoomPersonsModel {
  adult: number;
  child: number;
  ages: number[];
}


export class ChildAge {
  room: number;
  child_total: number;
  childrens: { child_age: number, child_number: number }[];
}



