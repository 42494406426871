export class UserModel {
  activation_code: string;
  created_at: string;
  email: string;
  email_verified_at: string;
  id: number;
  last_name: string;
  name: string;
  phone_number: string;
  remember_token: string;
  updated_at: string;
  user_info: UserInfoModel;
}

export class UserInfoModel {
  address1: string;
  address2: string;
  city: string;
  country_id: number;
  created_at: string;
  ext_number: string;
  id: number;
  int_number: string;
  state: string;
  updated_at: string;
  user_id: number;
  zip_code: number;
}

