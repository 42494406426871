export class CountryModel {
  created_at: string;
  ggt_id: number;
  hdo_id: string;
  id: number;
  iso_code: string;
  name: string;
  order: number;
  phone_country_code: string;
  updated_at: string;
}



