import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {HotelService} from '../../services/hotel.service';
import {HotelInfoModel, HotelModel, SearchModel} from '../../models';
import {RequestParams} from '../../core/api/api.model';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';

declare function closeWindow(event: any, wID: any);

@Component({
  selector: 'app-w-hotel-details',
  templateUrl: './w-hotel-details.component.html',
  styleUrls: ['./w-hotel-details.component.css']
})
export class WHotelDetailsComponent implements OnInit, OnDestroy {

  viewModeTW1 = 'w-tab-1';
  hotelSelected: HotelModel;
  subscriptionObject: Subscription;
  search: SearchModel;

  // noinspection SpellCheckingInspection
  id = 'idxxx';
  // noinspection SpellCheckingInspection
  imgsTotal = 5;
  currImg = 0;

  constructor(
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _hotelService: HotelService) {
  }

  ngOnInit() {
    this.currImg = 0;
    this.subscriptionObject = new Subscription();
    this.listenOpenWindow();
    this.search = this._hotelService.getReservation() ? this._hotelService.getReservation().search : null;
  }

  ngOnDestroy(): void {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }


  listenOpenWindow() {
    this.subscriptionObject.add(this._hotelService._fireOpenDetailWindow.subscribe(
      (data) => {
        this.hotelSelected = data.hotel;
        this.viewModeTW1 = 'w-tab-' + data.tabId;
        this.currImg = 0;
        this.search = this._hotelService.getReservation().search;
        if (!this.hotelSelected.details_data) {
          this.getHotelDetails();
        } else {
          this.imgsTotal = this.hotelSelected.details_data.pictures.length;
        }
      }
    ));

  }

  getHotelDetails() {
    const params: RequestParams = {

      params: {
        id: this.hotelSelected.id
      },
      search: {
        id_search_code: this.hotelSelected.offers[0].hotel_search_code
      }
    };
    this.spinner.show().then();
    this._hotelService.getHotelDetails(params).subscribe(
      (data) => {
        this.spinner.hide().then();
        this.hotelSelected.details_data = data.data;
        this.imgsTotal = this.hotelSelected.details_data.pictures.length;
      }, (error) => {
        this.spinner.hide().then();
        if (error.name && error.name === 'TimeoutError') {
          Swal.fire({
            title: 'A time out error occurred',
            text: 'The provider is temporarily out of service. Repeat your request.',
            type: 'error',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: ' Repeat request'
          }).then((result) => {
            if (result.value) {
              this.getHotelDetails();
            } else {
              // noinspection JSDeprecatedSymbols
              this.onCloseWindow(event, 'w-about-hotel');
            }
          });
        }
      }
    );
  }

  // ----------------------------------- /Images

  onErrorImage(event, i) {
    this.imgsTotal -= 1;
    this.hotelSelected.details_data.pictures = this.hotelSelected.details_data.pictures.filter((element, index) => {
      return i !== index;
    });
    if (this.imgsTotal === 0) {
      this.imgsTotal = 1;
    }
  }

  changeViewMode(wm) {
    this.viewModeTW1 = wm;
  }


  onPrevImg(event) {
    event.stopPropagation();
    //
    this.currImg--;
    this.currImg = this.currImg < 0 ? this.imgsTotal - 1 : this.currImg;
  }

  onNextImg(event) {
    event.stopPropagation();
    //
    this.currImg++;
    this.currImg = this.currImg > this.imgsTotal - 1 ? 0 : this.currImg;
  }

  // ----------------------------------- /Images

  // --------------------------    /MAP

  googleAPIMapLink() {
    let mapUrl = 'https://www.google.com/maps/embed/v1/place?q=';
    mapUrl += this.hotelSelected.details_data.latitude + ',' + this.hotelSelected.details_data.longitude;
    mapUrl += '&key=';
    // noinspection SpellCheckingInspection
    mapUrl += 'AIzaSyDjL19EuV-7T1PQc9IV_a1M6Y2jFvkzMSE';
    return mapUrl;
  }

  // --------------------------   /MAP

  onCloseWindow(event, wID) {
    event.stopPropagation();
    //
    this.spinner.hide().then();
    closeWindow(event, wID);
  }

  onToggleSlideshow(event) {
    event.stopPropagation();
    //
    document.getElementById('w-hotel-details').classList.toggle('alt');
    document.getElementsByClassName('swap-gc')[0].classList.toggle('active');
  }

}
