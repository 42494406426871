import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HotelService} from '../../services/hotel.service';

declare function clearNav(): any;

declare function imageContainer(arW: any, arH: any);

@Component({
  selector: 'app-hotel-search',
  templateUrl: './hotel-search.component.html',
  styleUrls: ['./hotel-search.component.css']
})
export class HotelSearchComponent implements OnInit {

  featureHotelFirst = {
    search: {
      destination_id: 7548,
      check_in: '2019-07-29',
      check_out: '2019-08-01',
      rooms_number: 1,
      rooms_persons: [{
        adult: 2,
        child: 0,
        ages: []
      }
      ],
      myDateRange: {
        beginDate: {
          year: 2019,
          month: 7,
          day: 29
        },
        beginJsDate: '2019-07-29T06:00:00.000Z',
        endDate: {
          year: 2019,
          month: 8,
          day: 1
        },
        endJsDate: '2019-08-01T06:00:00.000Z',
        formatted: '07/29/2019 - 08/01/2019',
        // noinspection SpellCheckingInspection
        beginEpoc: 1564380000,
        // noinspection SpellCheckingInspection
        endEpoc: 1564639200
      },
      destination: null,
      adults: 2,
      // noinspection SpellCheckingInspection
      childrens: 0
    },
    coming_from_search: true,
    offer: null,
    hotel: {
      id: 370163,
      hotel_code: '370163',
      hotel_name: 'THOMPSON PLAYA DEL CARMEN',
      country_id: 58,
      provider: {
        id: 1,
        name: 'Go GLobal Travel'
      },
      stars: 4,
      location: 'DOWNTOWN',
      location_code: '',
      thumbnail: '../../assets/images/hotel/hotelSample/thompson-playa.jpg',
      low_cost: 8,
      low_cost_currency: 'USD',
      offers: [
        {
          room_basis: 'RO',
          room_type: 'Room KING SIZE BED NON-REFUNDABLE',
          room_type_array: ['Room KING SIZE BED NON-REFUNDABLE'],
          hotel_search_code: '1864904\/3753269209130845471\/30',
          room_basis_description: 'ROOM ONLY',
          availability: 1,
          base_price: 7,
          total_price: 8,
          currency: 'USD',
          cxl_deadline: '03-07-2019',
          preferred: 0,
          // tslint:disable-next-line:max-line-length
          remark: 'NO AMENDMENTS POSSIBLE. IF NEEDED, PLEASE CANCEL AND REBOOK!!<BR \/> CXL charges apply as follows:  STARTING 03\/07\/2019 CXL-PENALTY FEE IS 100.00% OF BOOKING PRICE.',
          // tslint:disable-next-line:max-line-length
          special_offer: 'IMMEDIATE FULL CANCELLATION CHARGES APPLY AT THE TIME OF BOOKING, NO CHANGES ALLOWED, 100% OF TOTAL BOOKING AMOUNT IS NON-REFUNDABLE',
        }],
      details_data: null
    },
    list_hotels: null,
    featuredHotel: true,
    actual_time: 0,
    previousPage: 'hotel.search'
  };
  featureHotelSecond = {
    search: {
      destination_id: 7548,
      check_in: '2019-07-17',
      check_out: '2019-07-20',
      rooms_number: 1,
      rooms_persons: [{
        adult: 2,
        child: 0,
        ages: []
      }
      ],
      myDateRange: {
        beginDate: {
          year: 2019,
          month: 7,
          day: 17
        },
        beginJsDate: '2019-07-17T06:00:00.000Z',
        endDate: {
          year: 2019,
          month: 7,
          day: 20
        },
        endJsDate: '2019-07-20T06:00:00.000Z',
        formatted: '07/17/2019 - 07/20/2019',
        // noinspection SpellCheckingInspection
        beginEpoc: 1563343200,
        // noinspection SpellCheckingInspection
        endEpoc: 1563602400
      },
      destination: null,
      adults: 2,
      // noinspection SpellCheckingInspection
      childrens: 0
    },
    coming_from_search: true,
    offer: null,
    hotel: {
      id: 366695,
      hotel_code: '366695',
      hotel_name: 'HM PLAYA DEL CARMEN',
      country_id: 58,
      provider: {
        id: 1,
        name: 'Go GLobal Travel'
      },
      stars: 4,
      location: 'NEAR CENTRE',
      location_code: '',
      thumbnail: '../../assets/images/hotel/hotelSample/hm-playa.jpg',
      low_cost: 8,
      low_cost_currency: 'USD',
      offers: [{
        room_basis: 'RO',
        room_type: 'SINGLE STANDARD NON-REFUNDABLE',
        room_type_array: ['SINGLE STANDARD NON-REFUNDABLE'],
        hotel_search_code: '1864301\/3433013163617955351\/0',
        room_basis_description: 'ROOM ONLY',
        availability: 1,
        base_price: 7,
        total_price: 8,
        currency: 'USD',
        cxl_deadline: '03-07-2019',
        preferred: 0,
        // tslint:disable-next-line:max-line-length
        remark: 'NO AMENDMENTS POSSIBLE. IF NEEDED, PLEASE CANCEL AND REBOOK!!<BR \/> CXL charges apply as follows:  STARTING 03\/07\/2019 CXL-PENALTY FEE IS 100.00% OF BOOKING PRICE.',
        // tslint:disable-next-line:max-line-length
        special_offer: 'IMMEDIATE FULL CANCELLATION CHARGES APPLY AT THE TIME OF BOOKING, NO CHANGES ALLOWED, 100% OF TOTAL BOOKING AMOUNT IS NON-REFUNDABLE'
      }],
      details_data: null
    },
    list_hotels: null,
    featuredHotel: true,
    actual_time: 0,
    previousPage: 'hotel.search'
  };
  featureHotelThird = {
    search: {
      destination_id: 7564,
      check_in: '2019-07-23',
      check_out: '2019-07-26',
      rooms_number: 1,
      rooms_persons: [{
        adult: 2,
        child: 0,
        ages: []
      }
      ],
      myDateRange: {
        beginDate: {
          year: 2019,
          month: 7,
          day: 23
        },
        beginJsDate: '2019-07-23T06:00:00.000Z',
        endDate: {
          year: 2019,
          month: 7,
          day: 26
        },
        endJsDate: '2019-07-26T06:00:00.000Z',
        formatted: '07/23/2019 - 07/26/2019',
        // noinspection SpellCheckingInspection
        beginEpoc: 1563861600,
        // noinspection SpellCheckingInspection
        endEpoc: 1564120800
      },
      destination: null,
      adults: 2,
      // noinspection SpellCheckingInspection
      childrens: 0
    },
    coming_from_search: true,
    offer: null,
    hotel: {
      id: 446855,
      hotel_code: '446855',
      hotel_name: 'CASA MEXICANA COZUMEL',
      country_id: 58,
      provider: {
        id: 1,
        name: 'Go GLobal Travel'
      },
      stars: 3,
      location: 'COZUMEL',
      location_code: '0',
      thumbnail: '../../assets/images/hotel/hotelSample/casa-mexicana-cozumel.jpg',
      low_cost: 716,
      low_cost_currency: 'USD',
      offers: [{
        room_basis: 'BB',
        room_type: 'DOUBLE DELUXE CITY OR TERRACE VIEW',
        room_type_array: ['DOUBLE DELUXE CITY OR TERRACE VIEW'],
        hotel_search_code: '10411313\/135996861939566833\/0',
        room_basis_description: 'BED AND BREAKFAST',
        availability: 1,
        base_price: 701,
        total_price: 716,
        currency: 'USD',
        cxl_deadline: '18-07-2019',
        preferred: 0,
        remark: ' IN CASE OF AMENDMENTS FROM 04\/07\/2019\/NAME CHANGES FROM 04\/07\/2019, RESERVATIONS HAVE TO BE CANCELLED AND REBOOKED SUBJECT TO AVAILABILITY AND RATES AT THE TIME OF REBOOKING<br \/>Minimum check-in age 18. <br \/>STARTING 18\/07\/2019 CXL-PENALTY FEE IS 33.33% OF BOOKING PRICE.<br \/>Continental Room and Breakfast',
        special_offer: ''
      }],
      details_data: null
    },
    list_hotels: null,
    featuredHotel: true,
    actual_time: 0,
    previousPage: 'hotel.search'
  };
  featureHotelFourth = {
    search: {
      destination_id: 7434,
      check_in: '2019-07-29',
      check_out: '2019-08-01',
      rooms_number: 1,
      rooms_persons: [{
        adult: 2,
        child: 0,
        ages: []
      }
      ],
      myDateRange: {
        beginDate: {
          year: 2019,
          month: 7,
          day: 29
        },
        beginJsDate: '2019-07-29T06:00:00.000Z',
        endDate: {
          year: 2019,
          month: 8,
          day: 1
        },
        endJsDate: '2019-08-01T06:00:00.000Z',
        formatted: '07/29/2019 - 08/01/2019',
        // noinspection SpellCheckingInspection
        beginEpoc: 1564380000,
        // noinspection SpellCheckingInspection
        endEpoc: 1564639200
      },
      destination: null,
      adults: 2,
      // noinspection SpellCheckingInspection
      childrens: 0
    },
    coming_from_search: true,
    offer: null,
    hotel: {
      id: 35173,
      hotel_code: '35173',
      hotel_name: 'LIVE AQUA',
      country_id: 58,
      provider: {
        id: 1,
        name: 'Go GLobal Travel'
      },
      stars: 5,
      location: 'NEAR LA ISLA SHOPPING MALL',
      location_code: '',
      thumbnail: '../../assets/images/hotel/hotelSample/live-aqua-cancun.jpg',
      low_cost: 8,
      low_cost_currency: 'USD',
      offers: [{
        room_basis: 'RO',
        room_type: 'Room DELUXE GARDEN VIEW',
        room_type_array: ['Room DELUXE GARDEN VIEW'],
        hotel_search_code: '51599\/8182606603352981540\/0',
        room_basis_description: 'ROOM ONLY',
        availability: '1',
        base_price: '7',
        total_price: 8,
        currency: 'USD',
        cxl_deadline: '19-07-2019',
        preferred: '0',
        remark: 'NO AMENDMENTS POSSIBLE. IF NEEDED, PLEASE CANCEL AND REBOOK!!, ADULTS ONLY HOTEL<BR \/> CXL charges apply as follows:  STARTING 19\/07\/2019 CXL-PENALTY FEE IS 49.92% OF BOOKING PRICE. STARTING 26\/07\/2019 CXL-PENALTY FEE IS 100.00% OF BOOKING PRICE.',
        special_offer: ''
      }],
      details_data: null
    },
    list_hotels: null,
    featuredHotel: true,
    actual_time: 0,
    previousPage: 'hotel.search'
  };
  featureHotelFifth = {
    search: {
      destination_id: 7497,
      check_in: '2019-08-20',
      check_out: '2019-08-23',
      rooms_number: 1,
      rooms_persons: [{
        adult: 2,
        child: 0,
        ages: []
      }
      ],
      myDateRange: {
        beginDate: {
          year: 2019,
          month: 8,
          day: 20
        },
        beginJsDate: '2019-08-23T06:00:00.000Z',
        endDate: {
          year: 2019,
          month: 8,
          day: 23
        },
        endJsDate: '2019-08-23T06:00:00.000Z',
        formatted: '08/20/2019 - 08/23/2019',
        // noinspection SpellCheckingInspection
        beginEpoc: 1566280800,
        // noinspection SpellCheckingInspection
        endEpoc: 1566540000
      },
      destination: null,
      adults: 2,
      // noinspection SpellCheckingInspection
      childrens: 0
    },
    coming_from_search: true,
    offer: null,
    hotel: {
      id: 148734,
      hotel_code: '148734',
      hotel_name: 'BAHIA CHAC CHI',
      country_id: 58,
      provider: {
        id: 1,
        name: 'Go GLobal Travel'
      },
      stars: 3,
      location: 'CITY CENTRE',
      location_code: '',
      thumbnail: '../../assets/images/hotel/hotelSample/bahia-chac-chi-isla.jpg',
      low_cost: 419,
      low_cost_currency: 'USD',
      offers: [{
        room_basis: 'BB',
        room_type: 'DOUBLE BAY VIEW',
        room_type_array: ['DOUBLE BAY VIEW'],
        hotel_search_code: '561680\/4949747095236378807\/0',
        room_basis_description: 'BED AND BREAKFAST',
        availability: '1',
        base_price: '410',
        total_price: 419,
        currency: 'USD',
        cxl_deadline: '16-08-2019',
        preferred: '0',
        remark: '<BR \/> CXL charges apply as follows:  STARTING 16\/08\/2019 CXL-PENALTY FEE IS 100.00% OF BOOKING PRICE.',
        special_offer: ''
      }],
      details_data: null
    },
    list_hotels: null,
    featuredHotel: true,
    actual_time: 0,
    previousPage: 'hotel.search'
  };


  featuredHotels;

  constructor(
    private _hotelService: HotelService,
    private _router: Router
  ) {
  }

  ngOnInit() {
    //
    imageContainer(3, 2);
    clearNav();
    document.getElementById('mm1').classList.add('active');

    this.featuredHotels = [
      this.featureHotelFirst,
      this.featureHotelSecond,
      this.featureHotelThird,
      this.featureHotelFourth,
      this.featureHotelFifth
    ];
  }

  onSelectedHotel(hotelID) {
    return;
    this._hotelService.resetReservation();
    const reservation = this.featuredHotels[hotelID];
    reservation.actual_time = new Date().getTime();


    this._hotelService.setReservation(reservation);
    this._router.navigate(['/hotel']).then();
  }

  // onSelectedHotel(hotelID) {
  //   //
  //   this._router.navigate(['/hotel']);
  // }

  onResize($event) {
    //
    imageContainer(3, 2);
  }

}
