import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HotelSearchComponent} from './hotel/hotel-search/hotel-search.component';
import {PFooterComponent} from './p-footer/p-footer.component';
import {PNavbarComponent} from './p-navbar/p-navbar.component';
import {RegisterComponent} from './register/register.component';
import {HotelsComponent} from './hotel/hotels/hotels.component';
import {FormHotelSearchComponent} from './hotel/form-hotel-search/form-hotel-search.component';
import {HotelComponent} from './hotel/hotel/hotel.component';
import {BookingHotelComponent} from './booking/booking-hotel/booking-hotel.component';
import {SummaryHotelComponent} from './hotel/summary-hotel/summary-hotel.component';
import {PaymentHotelComponent} from './payment/payment-hotel/payment-hotel.component';
import {WHotelDetailsComponent} from './hotel/w-hotel-details/w-hotel-details.component';
import {WRoomsGuestsComponent} from './hotel/form-hotel-search/w-rooms-guests/w-rooms-guests.component';
import {WOfferDetailComponent} from './hotel/w-offer-detail/w-offer-detail.component';
import {MyBookingsComponent} from './my-bookings/my-bookings.component';
import {TabHotelsComponent} from './my-bookings/tab-hotels/tab-hotels.component';
import {WMapComponent} from './windows/w-map/w-map.component';
import {WLoginComponent} from './windows/w-login/w-login.component';
import {HomeComponent} from './home/home.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgSelectModule} from '@ng-select/ng-select';
import {MyDateRangePickerModule} from 'mydaterangepicker';
import {NgxMyDatePickerModule} from 'ngx-mydatepicker';
import {NgxStripeModule} from '@nomadreservations/ngx-stripe';
import {CurrencyPipe} from '@angular/common';
import {LoginService} from './services/login.service';
import {ApiService} from './core/api/api.service';
import {RequestInterceptor} from './core/util/request.interceptor';
import {UserGuard} from './services/guards/user-guard.service';
import {CommonService} from './services/common.service';
import {UserService} from './services/user.service';
import {HotelService} from './services/hotel.service';
import {LimitToDirective} from './core/directives/limit-to.directive';
import {PaymentConfirmationComponent} from './payment/payment-confirmation/payment-confirmation.component';
import {SanitizerPipe} from './pipes/sanitizer.pipe';
import {NgxGalleryModule} from 'ngx-gallery';
import {WUnderConstructionComponent} from './windows/w-under-construction/w-under-construction.component';
import {WUnderConstructionYComponent} from './windows/w-under-construction-y/w-under-construction-y.component';
import {LetterSpacesOnlyDirective} from './core/directives/letter-spaces-only.directive';
import {BillingAddressDirective} from './core/directives/billing-address.directive';
import {LettersOnlyDirective} from './core/directives/letters-only.directive';
import {NumbersOnlyDirective} from './core/directives/numbers-only.directive';
import {AlphanumericOnlyDirective} from './core/directives/alphanumeric-only.directive';
import {AlphanumericSpacesOnlyDirective} from './core/directives/alphanumeric-spaces-only.directive';
import {SecurityDirective} from './core/directives/security.directive';
import {OnlyNumbersDirective} from './core/directives/only-numbers.directive';
import {NauticalComponent} from './nautica/nautica/nautical.component';
import {BookingNauticalComponent} from './booking/booking-nautica/booking-nautical.component';
import {SummaryNauticalComponent} from './nautica/summary-nautica/summary-nautical.component';
import { WTourInfoComponent } from './nautica/w-tour-info/w-tour-info.component';
import { MoreContactComponent } from './more-contact/more-contact.component';

@NgModule({
  declarations: [
    AppComponent,
    LimitToDirective,
    HotelSearchComponent,
    PFooterComponent,
    PNavbarComponent,
    RegisterComponent,
    HotelsComponent,
    FormHotelSearchComponent,
    HotelComponent,
    BookingHotelComponent,
    SummaryHotelComponent,
    PaymentHotelComponent,
    WHotelDetailsComponent,
    WRoomsGuestsComponent,
    WOfferDetailComponent,
    MyBookingsComponent,
    TabHotelsComponent,
    WMapComponent,
    WLoginComponent,
    HomeComponent,
    SanitizerPipe,
    PaymentConfirmationComponent,
    WUnderConstructionComponent,
    WUnderConstructionYComponent,
    LettersOnlyDirective,
    NumbersOnlyDirective,
    AlphanumericOnlyDirective,
    LetterSpacesOnlyDirective,
    AlphanumericSpacesOnlyDirective,
    BillingAddressDirective,
    SecurityDirective,
    OnlyNumbersDirective,
    WUnderConstructionYComponent,
    NauticalComponent,
    BookingNauticalComponent,
    SummaryNauticalComponent,
    WTourInfoComponent,
    MoreContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgSelectModule,
    NgxMyDatePickerModule.forRoot(),
    FormsModule,
    MyDateRangePickerModule,
    NgxStripeModule.forRoot('pk_test_Xo84EKGoLWk8wBTrL82qJAVl'),
    NgxGalleryModule
  ],
  providers: [
    LoginService,
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    UserGuard,
    CommonService,
    UserService,
    HotelService,
    CurrencyPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}

