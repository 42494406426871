import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {HotelService} from '../services/hotel.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UserService} from '../services/user.service';
import {GeneralUtil} from '../core/util/general.util';
import {NauticalModel, NauticalReservationModel} from '../models/nautical.model';
import {NauticalService} from '../services/nautical.service';

declare function clearNav(): any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

  subscriptActivateAccount: Subscription;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  // - - - - - - - - - - - - - - - - - - - GALLERY DESCRIPTIONS
  gc1 = '<div class="gallery-text-container"><div class="px-15 py-1 i-name"><div class="hg-title ff-con">';
  gc2 = '</div><div class="hg-subtitle ff-con">';
  gc3 = '</div></div><div class="hg-city px-15 py-05 ff-rnd">';
  gc4 = '</div><div class="hg-price px-15 py-1 tar"><div class="fr tac"><span class="curr ff-con">$</span><span class="price ff-rnd">';
  gc5 = '</span></div><div class="fr tac"><span class="line1 ff-con block">FROM</span><span class="line2 ff-con block text-w">';
  gc6 = '</span></div><div class="cb"></div></div></div>';

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - VAR
  // movX = card-home w + margin = 296
  movX = 296;

  packagesList: NauticalModel[];

  featureHotelFirst = {
    search: {
      destination_id: 7548,
      check_in: '2019-07-29',
      check_out: '2019-08-01',
      rooms_number: 1,
      rooms_persons: [{
        adult: 2,
        child: 0,
        ages: []
      }
      ],
      myDateRange: {
        beginDate: {
          year: 2019,
          month: 7,
          day: 29
        },
        beginJsDate: '2019-07-29T06:00:00.000Z',
        endDate: {
          year: 2019,
          month: 8,
          day: 1
        },
        endJsDate: '2019-08-01T06:00:00.000Z',
        formatted: '07/29/2019 - 08/01/2019',
        // noinspection SpellCheckingInspection
        beginEpoc: 1564380000,
        // noinspection SpellCheckingInspection
        endEpoc: 1564639200
      },
      destination: null,
      adults: 2,
      // noinspection SpellCheckingInspection
      childrens: 0
    },
    coming_from_search: true,
    offer: null,
    hotel: {
      id: 370163,
      hotel_code: '370163',
      hotel_name: 'THOMPSON PLAYA DEL CARMEN',
      country_id: 58,
      provider: {
        id: 1,
        name: 'Go GLobal Travel'
      },
      stars: 4,
      location: 'DOWNTOWN',
      location_code: '',
      thumbnail: '../../assets/images/hotel/hotelSample/thompson-playa.jpg',
      low_cost: 8,
      low_cost_currency: 'USD',
      offers: [
        {
          room_basis: 'RO',
          room_type: 'Room KING SIZE BED NON-REFUNDABLE',
          room_type_array: ['Room KING SIZE BED NON-REFUNDABLE'],
          hotel_search_code: '1864904\/3753269209130845471\/30',
          room_basis_description: 'ROOM ONLY',
          availability: 1,
          base_price: 7,
          total_price: 8,
          currency: 'USD',
          cxl_deadline: '03-07-2019',
          preferred: 0,
          // tslint:disable-next-line:max-line-length
          remark: 'NO AMENDMENTS POSSIBLE. IF NEEDED, PLEASE CANCEL AND REBOOK!!<BR \/> CXL charges apply as follows:  STARTING 03\/07\/2019 CXL-PENALTY FEE IS 100.00% OF BOOKING PRICE.',
          // tslint:disable-next-line:max-line-length
          special_offer: 'IMMEDIATE FULL CANCELLATION CHARGES APPLY AT THE TIME OF BOOKING, NO CHANGES ALLOWED, 100% OF TOTAL BOOKING AMOUNT IS NON-REFUNDABLE',
        }],
      details_data: null
    },
    list_hotels: null,
    featuredHotel: true,
    actual_time: 0,
    previousPage: 'home'
  };
  featureHotelSecond = {
    search: {
      destination_id: 7548,
      check_in: '2019-07-17',
      check_out: '2019-07-20',
      rooms_number: 1,
      rooms_persons: [{
        adult: 2,
        child: 0,
        ages: []
      }
      ],
      myDateRange: {
        beginDate: {
          year: 2019,
          month: 7,
          day: 17
        },
        beginJsDate: '2019-07-17T06:00:00.000Z',
        endDate: {
          year: 2019,
          month: 7,
          day: 20
        },
        endJsDate: '2019-07-20T06:00:00.000Z',
        formatted: '07/17/2019 - 07/20/2019',
        // noinspection SpellCheckingInspection
        beginEpoc: 1563343200,
        // noinspection SpellCheckingInspection
        endEpoc: 1563602400
      },
      destination: null,
      adults: 2,
      // noinspection SpellCheckingInspection
      childrens: 0
    },
    coming_from_search: true,
    offer: null,
    hotel: {
      id: 366695,
      hotel_code: '366695',
      hotel_name: 'HM PLAYA DEL CARMEN',
      country_id: 58,
      provider: {
        id: 1,
        name: 'Go GLobal Travel'
      },
      stars: 4,
      location: 'NEAR CENTRE',
      location_code: '',
      thumbnail: '../../assets/images/hotel/hotelSample/hm-playa.jpg',
      low_cost: 8,
      low_cost_currency: 'USD',
      offers: [{
        room_basis: 'RO',
        room_type: 'SINGLE STANDARD NON-REFUNDABLE',
        room_type_array: ['SINGLE STANDARD NON-REFUNDABLE'],
        hotel_search_code: '1864301\/3433013163617955351\/0',
        room_basis_description: 'ROOM ONLY',
        availability: 1,
        base_price: 7,
        total_price: 8,
        currency: 'USD',
        cxl_deadline: '03-07-2019',
        preferred: 0,
        // tslint:disable-next-line:max-line-length
        remark: 'NO AMENDMENTS POSSIBLE. IF NEEDED, PLEASE CANCEL AND REBOOK!!<BR \/> CXL charges apply as follows:  STARTING 03\/07\/2019 CXL-PENALTY FEE IS 100.00% OF BOOKING PRICE.',
        // tslint:disable-next-line:max-line-length
        special_offer: 'IMMEDIATE FULL CANCELLATION CHARGES APPLY AT THE TIME OF BOOKING, NO CHANGES ALLOWED, 100% OF TOTAL BOOKING AMOUNT IS NON-REFUNDABLE'
      }],
      details_data: null
    },
    list_hotels: null,
    featuredHotel: true,
    actual_time: 0,
    previousPage: 'home'
  };
  featureHotelThird = {
    search: {
      destination_id: 7564,
      check_in: '2019-07-23',
      check_out: '2019-07-26',
      rooms_number: 1,
      rooms_persons: [{
        adult: 2,
        child: 0,
        ages: []
      }
      ],
      myDateRange: {
        beginDate: {
          year: 2019,
          month: 7,
          day: 23
        },
        beginJsDate: '2019-07-23T06:00:00.000Z',
        endDate: {
          year: 2019,
          month: 7,
          day: 26
        },
        endJsDate: '2019-07-26T06:00:00.000Z',
        formatted: '07/23/2019 - 07/26/2019',
        // noinspection SpellCheckingInspection
        beginEpoc: 1563861600,
        // noinspection SpellCheckingInspection
        endEpoc: 1564120800
      },
      destination: null,
      adults: 2,
      // noinspection SpellCheckingInspection
      childrens: 0
    },
    coming_from_search: true,
    offer: null,
    hotel: {
      id: 446855,
      hotel_code: '446855',
      hotel_name: 'CASA MEXICANA COZUMEL',
      country_id: 58,
      provider: {
        id: 1,
        name: 'Go GLobal Travel'
      },
      stars: 3,
      location: 'COZUMEL',
      location_code: '0',
      thumbnail: '../../assets/images/hotel/hotelSample/casa-mexicana-cozumel.jpg',
      low_cost: 716,
      low_cost_currency: 'USD',
      offers: [{
        room_basis: 'BB',
        room_type: 'DOUBLE DELUXE CITY OR TERRACE VIEW',
        room_type_array: ['DOUBLE DELUXE CITY OR TERRACE VIEW'],
        hotel_search_code: '10411313\/135996861939566833\/0',
        room_basis_description: 'BED AND BREAKFAST',
        availability: 1,
        base_price: 701,
        total_price: 716,
        currency: 'USD',
        cxl_deadline: '18-07-2019',
        preferred: 0,
        // tslint:disable-next-line:max-line-length
        remark: ' IN CASE OF AMENDMENTS FROM 04\/07\/2019\/NAME CHANGES FROM 04\/07\/2019, RESERVATIONS HAVE TO BE CANCELLED AND REBOOKED SUBJECT TO AVAILABILITY AND RATES AT THE TIME OF REBOOKING<br \/>Minimum check-in age 18. <br \/>STARTING 18\/07\/2019 CXL-PENALTY FEE IS 33.33% OF BOOKING PRICE.<br \/>Continental Room and Breakfast',
        special_offer: ''
      }],
      details_data: null
    },
    list_hotels: null,
    featuredHotel: true,
    actual_time: 0,
    previousPage: 'home'
  };
  featureHotelFourth = {
    search: {
      destination_id: 7434,
      check_in: '2019-07-29',
      check_out: '2019-08-01',
      rooms_number: 1,
      rooms_persons: [{
        adult: 2,
        child: 0,
        ages: []
      }
      ],
      myDateRange: {
        beginDate: {
          year: 2019,
          month: 7,
          day: 29
        },
        beginJsDate: '2019-07-29T06:00:00.000Z',
        endDate: {
          year: 2019,
          month: 8,
          day: 1
        },
        endJsDate: '2019-08-01T06:00:00.000Z',
        formatted: '07/29/2019 - 08/01/2019',
        // noinspection SpellCheckingInspection
        beginEpoc: 1564380000,
        // noinspection SpellCheckingInspection
        endEpoc: 1564639200
      },
      destination: null,
      adults: 2,
      // noinspection SpellCheckingInspection
      childrens: 0
    },
    coming_from_search: true,
    offer: null,
    hotel: {
      id: 35173,
      hotel_code: '35173',
      hotel_name: 'LIVE AQUA',
      country_id: 58,
      provider: {
        id: 1,
        name: 'Go GLobal Travel'
      },
      stars: 5,
      location: 'NEAR LA ISLA SHOPPING MALL',
      location_code: '',
      thumbnail: '../../assets/images/hotel/hotelSample/live-aqua-cancun.jpg',
      low_cost: 8,
      low_cost_currency: 'USD',
      offers: [{
        room_basis: 'RO',
        room_type: 'Room DELUXE GARDEN VIEW',
        room_type_array: ['Room DELUXE GARDEN VIEW'],
        hotel_search_code: '51599\/8182606603352981540\/0',
        room_basis_description: 'ROOM ONLY',
        availability: '1',
        base_price: '7',
        total_price: 8,
        currency: 'USD',
        cxl_deadline: '19-07-2019',
        preferred: '0',
        // tslint:disable-next-line:max-line-length
        remark: 'NO AMENDMENTS POSSIBLE. IF NEEDED, PLEASE CANCEL AND REBOOK!!, ADULTS ONLY HOTEL<BR \/> CXL charges apply as follows:  STARTING 19\/07\/2019 CXL-PENALTY FEE IS 49.92% OF BOOKING PRICE. STARTING 26\/07\/2019 CXL-PENALTY FEE IS 100.00% OF BOOKING PRICE.',
        special_offer: ''
      }],
      details_data: null
    },
    list_hotels: null,
    featuredHotel: true,
    actual_time: 0,
    previousPage: 'home'
  };
  featureHotelFifth = {
    search: {
      destination_id: 7497,
      check_in: '2019-08-20',
      check_out: '2019-08-23',
      rooms_number: 1,
      rooms_persons: [{
        adult: 2,
        child: 0,
        ages: []
      }
      ],
      myDateRange: {
        beginDate: {
          year: 2019,
          month: 8,
          day: 20
        },
        beginJsDate: '2019-08-23T06:00:00.000Z',
        endDate: {
          year: 2019,
          month: 8,
          day: 23
        },
        endJsDate: '2019-08-23T06:00:00.000Z',
        formatted: '08/20/2019 - 08/23/2019',
        // noinspection SpellCheckingInspection
        beginEpoc: 1566280800,
        // noinspection SpellCheckingInspection
        endEpoc: 1566540000
      },
      destination: null,
      adults: 2,
      // noinspection SpellCheckingInspection
      childrens: 0
    },
    coming_from_search: true,
    offer: null,
    hotel: {
      id: 148734,
      hotel_code: '148734',
      hotel_name: 'BAHIA CHAC CHI',
      country_id: 58,
      provider: {
        id: 1,
        name: 'Go GLobal Travel'
      },
      stars: 3,
      location: 'CITY CENTRE',
      location_code: '',
      thumbnail: '../../assets/images/hotel/hotelSample/bahia-chac-chi-isla.jpg',
      low_cost: 419,
      low_cost_currency: 'USD',
      offers: [{
        room_basis: 'BB',
        room_type: 'DOUBLE BAY VIEW',
        room_type_array: ['DOUBLE BAY VIEW'],
        hotel_search_code: '561680\/4949747095236378807\/0',
        room_basis_description: 'BED AND BREAKFAST',
        availability: '1',
        base_price: '410',
        total_price: 419,
        currency: 'USD',
        cxl_deadline: '16-08-2019',
        preferred: '0',
        remark: '<BR \/> CXL charges apply as follows:  STARTING 16\/08\/2019 CXL-PENALTY FEE IS 100.00% OF BOOKING PRICE.',
        special_offer: ''
      }],
      details_data: null
    },
    list_hotels: null,
    featuredHotel: true,
    actual_time: 0,
    previousPage: 'home'
  };


  featuredHotels;

  activationCode: string;
  redirect: string;

  constructor(
    private _hotelService: HotelService,
    private _routeHandler: ActivatedRoute,
    private _userService: UserService,
    private _nautService: NauticalService,
    private _router: Router) {
  }

  ngOnInit() {
    clearNav();
    document.getElementById('mm0').classList.add('active');

    this._routeHandler.queryParams.subscribe(params => {
      if (params['activation_code']) {
        this.subscriptActivateAccount = new Subscription();
        this.activationCode = params['activation_code'];
        this.activateAccount();
      }
    });

    this.getPackages().then();

    //
    this.galleryOptions = [
      {
        width: '100%',
        height: '100%',
        preview: false,
        // fullWidth: true,
        imageArrows: false,
        imageAutoPlay: true,
        thumbnails: false,
        imageDescription: true,
        imageAutoPlayPauseOnHover: true,
        imageAutoPlayInterval: 7000,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      },
    ];

    this.galleryImages = [
      {
        small: '../../assets/images/banner/bahia-chac-chi.jpg',
        medium: '../../assets/images/banner/bahia-chac-chi.jpg',
        big: '../../assets/images/banner/bahia-chac-chi.jpg',
        description: this.gc1 + 'Bahia Chac Chi' +
          this.gc2 + 'Hotel' +
          this.gc3 + 'Isla Mujeres' +
          this.gc4 + '208' +
          this.gc5 + 'USD' +
          this.gc6
      },
      {
        small: '../../assets/images/banner/romantic.jpg',
        medium: '../../assets/images/banner/romantic.jpg',
        big: '../../assets/images/banner/romantic.jpg',
        description: this.gc1 + 'Romantic Dinner' +
          this.gc2 + 'Yacht Tour' +
          this.gc3 + 'Cancun' +
          this.gc4 + '180' +
          this.gc5 + 'USD' +
          this.gc6
      },
      {
        small: '../../assets/images/banner/chichen-itza.jpg',
        medium: '../../assets/images/banner/chichen-itza.jpg',
        big: '../../assets/images/banner/chichen-itza.jpg',
        description: this.gc1 + 'Xichen Deluxe' +
          this.gc2 + 'Tour' +
          this.gc3 + 'Chichen Itza' +
          this.gc4 + '116' +
          this.gc5 + 'USD' +
          this.gc6
      },
      {
        small: '../../assets/images/banner/cancun-party.jpg',
        medium: '../../assets/images/banner/cancun-party.jpg',
        big: '../../assets/images/banner/cancun-party.jpg',
        description: this.gc1 + 'QROO Party' +
          this.gc2 + 'Bracelet' +
          this.gc3 + 'Cancun' +
          this.gc4 + '220' +
          this.gc5 + 'USD' +
          this.gc6
      }
    ];

    this.featuredHotels = [
      this.featureHotelFirst,
      this.featureHotelSecond,
      this.featureHotelThird,
      this.featureHotelFourth,
      this.featureHotelFifth
    ];
  }

  ngAfterViewInit() {
    this.setCards(event);
  }

  ngOnDestroy() {
    if (this.subscriptActivateAccount) {
      this.subscriptActivateAccount.unsubscribe();
    }
  }

  async getPackages() {
    this.packagesList = [];
    this.packagesList.push(
      {
        totalPeople: 20,
        location: 'Isla Mujeres VIP',
        package_name: 'Luxury 55\' Yacht - 6 Hours',
        duration: 6,
        images: [
          '../../assets/images/yacht/yachtSample/0.jpg',
          '../../assets/images/yacht/prestige/exterior 1.jpg',
          '../../assets/images/yacht/yachtSample/1.jpg',
          '../../assets/images/yacht/prestige/exterior 2.jpg',
          '../../assets/images/yacht/yachtSample/2.jpg'],
        features: [
          'Soft drinks, beers, and water bottles',
          'Snack varieties',
          'Towels, tanner and sunscreens',
          'Inflatable sea toys',
          'Docking fee included',
          'Experienced crew',
          'VIP Transportation from your hotel in Cancun and Playa del Carmen'
        ],
        price: 1800,
        package_information: [
          'Transportation included',
          'Up to 20 people',
          'Taxes & fees included'
        ],
        currency: 'USD',
        vipTransportation: true
      }
    );
    this.packagesList.push(
      {
        totalPeople: 20,
        location: 'Isla Mujeres VIP',
        package_name: 'Luxury 55\' Yacht - 8 Hours',
        duration: 8,
        images: [
          '../../assets/images/yacht/yachtSample/1.jpg',
          '../../assets/images/yacht/prestige/exterior 4.jpg',
          '../../assets/images/yacht/prestige/exterior 7.jpg',
          '../../assets/images/yacht/prestige/interior 2.jpg',
          '../../assets/images/yacht/prestige/interior 1.jpg',],
        features: [
          'Soft drinks, beers, and water bottles',
          'Snack varieties',
          'Towels, tanner and sunscreens',
          'Inflatable sea toys',
          'Docking fee included',
          'Experienced crew',
          'VIP Transportation from your hotel in Cancun and Playa del Carmen'
        ],
        price: 2200,
        package_information: [
          'Transportation included',
          'Up to 20 people',
          'Taxes & fees included'
        ],
        currency: 'USD',
        vipTransportation: true
      }
    );
    this.packagesList.push(
      {
        totalPeople: 4,
        location: 'Isla Mujeres VIP',
        package_name: 'Sunset marriage proposal',
        duration: 3,
        images: [
          '../../assets/images/yacht/yachtSample/3.jpg',
          '../../assets/images/yacht/prestige/exterior 3.jpg',
          '../../assets/images/yacht/prestige/exterior 9.jpg',
          '../../assets/images/yacht/prestige/interior 4.jpg',
          '../../assets/images/yacht/prestige/interior 3.jpg',],
        features: [
          'Champagne bottle',
          'Docking fee included ',
          'Experienced crew'
        ],
        price: 800,
        package_information: [
          'Up to 4 people',
          'Taxes & fees included'
        ],
        currency: 'USD',
        vipTransportation: false
      }
    );
  }

  activateAccount() {
    this.subscriptActivateAccount.add(this._userService.confirmAccount(this.activationCode).subscribe(
      (data) => {
        GeneralUtil.successMessage(data.message);
      }, (error) => {
        if (error.error.message) {
          GeneralUtil.errorMessage(error.error.message);
        }
        if (error.message) {
          GeneralUtil.errorMessage(error.message);
        }
      }
    ));
  }

  onResize(event) {
    event.stopPropagation();
    //
    // imageContainer(3, 2);
    this.setCards(event);
  }

  onSelectedHotel(hotelID) {
    return;
    this._hotelService.resetReservation();
    const reservation = this.featuredHotels[hotelID];
    reservation.actual_time = new Date().getTime();


    this._hotelService.setReservation(reservation);
    this._router.navigate(['/hotel']).then();
  }

  // PREGUNTAR POR QUÉ const EN LUGAR DE var Y QUÉ PASA CUANDO HAY WINDOWS RESIZE

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - SET CARDS()
  setCards(event) {
    const contS = document.getElementsByClassName('col-scr');
    for (let i = 0; i < contS.length; i++) {
      const col = contS[i] as HTMLDivElement;
      const colW = col.offsetWidth;
      const cardS = col.getElementsByClassName('card').length;
      // card-home w + lm = 280
      const cW = this.movX * cardS;
      const cont = col.getElementsByClassName('flex')[0] as HTMLDivElement;
      cont.style.width = cW + 'px';
      const actR = contS[i].parentElement.getElementsByClassName('act-r')[0];
      if (cW <= colW) {
        actR.classList.add('disabled');
      } else {
        actR.classList.remove('disabled');
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON LEFT()
  onLeft(event) {
    //
    const tElem = event.target as HTMLDivElement;
    const tCont = tElem.parentElement.getElementsByClassName('flex')[0] as HTMLDivElement;
    const contOL = tCont.offsetLeft;
    //
    if (tElem.classList.contains('disabled')) {
      // Do nothing
    } else {
      tElem.parentElement.getElementsByClassName('act-r')[0].classList.remove('disabled');
      // Scroll width
      const limit = tElem.parentElement.getElementsByClassName('cont-shadow')[0] as HTMLDivElement;
      const limitW = limit.offsetWidth;
      const scrollW = Math.floor(limitW / this.movX) * this.movX;
      //
      // var nOl = contOL + this.movX;
      let nOl = contOL + scrollW;
      const nOlmax = 0;
      if (nOl >= nOlmax) {
        nOl = nOlmax;
        tElem.classList.add('disabled');
      }
      //
      tCont.style.left = nOl + 'px';
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON RIGHT()
  onRight(event) {
    //
    const tElem = event.target as HTMLDivElement;
    const tCont = tElem.parentElement.getElementsByClassName('flex')[0] as HTMLDivElement;
    const contOL = tCont.offsetLeft;
    //
    if (tElem.classList.contains('disabled')) {
      // Do nothing
    } else {
      tElem.parentElement.getElementsByClassName('act-l')[0].classList.remove('disabled');
      //
      const contOW = tCont.offsetWidth;
      const colOW = tCont.parentElement.offsetWidth;
      // Scroll width
      const limit = tElem.parentElement.getElementsByClassName('cont-shadow')[0] as HTMLDivElement;
      const limitW = limit.offsetWidth;
      const scrollW = Math.floor(limitW / this.movX) * this.movX;
      //
      // var nOl = contOL - this.movX;
      let nOl = contOL - scrollW;
      const nOlmin = colOW - contOW;
      if (nOl <= nOlmin) {
        nOl = nOlmin;
        tElem.classList.add('disabled');
      }
      //
      tCont.style.left = nOl + 'px';
    }
  }

  onNauticalBook(event, nautPack) {
    event.stopPropagation();
    //
    const reservation = new NauticalReservationModel();
    reservation.nautical_package = nautPack;
    this._nautService.setReservation(reservation);
    this._router.navigate(['/nautica.booking']).then();
  }
}
