import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {HotelService} from '../../services/hotel.service';
import {HotelModel, OfferModel, PriceBreakDownModel, RoomPersonsModel, SearchModel} from '../../models';
import {Subscription} from 'rxjs';
import {GeneralUtil} from '../../core/util/general.util';

declare function openWindow(event: any, wID: any);

@Component({
  selector: 'app-summary-hotel',
  templateUrl: './summary-hotel.component.html',
  styleUrls: ['./summary-hotel.component.css']
})
export class SummaryHotelComponent implements OnInit {

  subscriptionObject: Subscription;
  subscriptionPriceBD: Subscription;

  offerData: OfferModel;
  hotelDates: any[];
  hotelData: HotelModel;
  adults: number;
  children: number;
  rooms: number;
  nonOffer: boolean = true;

  search: SearchModel;
  room_persons: RoomPersonsModel[];

  priceBreakDown: PriceBreakDownModel;

  constructor(
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _hotelService: HotelService
  ) {
  }

  ngOnInit() {

    this.subscriptionObject = new Subscription();

    this.hotelData = this._hotelService.getReservation().hotel;
    this.search = this._hotelService.getReservation().search;
    this.hotelDates = [this._hotelService.getReservation().search.check_in, this._hotelService.getReservation().search.check_out];
    this.room_persons = this._hotelService.getReservation().search.rooms_persons;
    this.offerData = this._hotelService.getReservation().offer;
    this.getRoomPersons();
    this.listenOffers();
    this.checkPriceBreakDown();
  }

  listenOffers() {
    this.subscriptionObject = this._hotelService._fireOfferChange.subscribe(
      (offer) => {
        this.offerData = offer;
        this.checkPriceBreakDown();
      }
    );
  }

  checkPriceBreakDown() {
    this.subscriptionPriceBD = new Subscription();
    if (this.hotelData.provider.id === 1 && this.offerData !== null) {
      this.spinner.show().then();
      this.subscriptionPriceBD.add(this._hotelService.getPriceBreakDown(this.offerData.hotel_search_code).subscribe(
        (data) => {
          this.spinner.hide().then();
          this.priceBreakDown = data.data;
        }, (error) => {
          this.spinner.hide().then();
          GeneralUtil.errorMessage(error.error.message);
        }
      ));
    }
  }

  getNights() {
    const dateIn = new Date(this.hotelDates[0]);
    const dateOut = new Date(this.hotelDates[1]);
    const nights = (dateOut.getTime() - dateIn.getTime()) / 1000;
    return Math.floor(nights / (3600 * 24));
  }

  getRoomPersons() {
    let adults = 0;
    let children = 0;
    let rooms;

    if (!this.room_persons || this.room_persons.length === 0) {
      return;
    }

    rooms = this.room_persons.length;
    for (const roomPerson of this.room_persons) {
      adults += roomPerson.adult;
      children += roomPerson.child;
    }

    this.adults = adults;
    this.children = children;
    this.rooms = rooms;

  }

  getRoomPrice(price, rooms){
    return Number(price / rooms);
  }

  onInfo($event, hotelID) {
    // IR A TAB 1
    openWindow(event, 'w-hotel-details');
  }

}
