export class NauticalModel {
  location: string;
  package_name: string;
  images: Array<string>;
  features: Array<string>;
  price: number;
  package_information: Array<string>;
  currency: string;
  vipTransportation: boolean;
  duration: number;
  totalPeople: number;
}


export class NauticalReservationModel {
  nautical_package: NauticalModel;

  user_id: number;

  no_guest: string;
  departure_date: string;
  departure_time: string;
  departure_harbour: string;

  transportation_hotel: string;
  transportation_address: string;

  extra_instructions: string;
}
