import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '../services/login.service';
import {UserModel} from '../models';
import {GeneralUtil} from '../core/util/general.util';
import Swal from 'sweetalert2';

declare function openWindowWithoutEvent(wID: any);
declare function under(event:any);

@Component({
  selector: 'app-p-navbar',
  templateUrl: './p-navbar.component.html',
  styleUrls: ['./p-navbar.component.css']
})
export class PNavbarComponent implements OnInit {

  userLogged: UserModel;

  constructor(
    private _router: Router,
    private _loginService: LoginService
  ) {
  }

  ngOnInit() {
    this.userLogged = this._loginService.getUserInfo();
    this.listenLogout();
  }

  listenLogout() {
    this._loginService._logged.subscribe(
      (data) => {
        if (!data) {
          this.userLogged = null;
        } else {
          this.userLogged = data;
        }
      }
    );
  }

  onLogout() {
    const currentUrl = this._router.url;
    Swal.fire({
      title: 'Warning!',
      text: 'Are you sure you wish to logout?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!'
    }).then((result) => {
      if (result.value) {
        this._loginService.doLogout().subscribe(
          async () => {
            this._loginService.fireLoginChange(null);
            GeneralUtil.successMessage('You have been logout!');
            localStorage.clear();
            this._router.navigate(['/home']).then();
          }, (err) => {
            if (err.status === 401) {
              this._loginService.fireLoginChange(null);
              GeneralUtil.successMessage('You have been logout!');
              localStorage.clear();
              this._router.navigate(['/home']).then();
              return;
            }
            if (err.error.message) {
              GeneralUtil.errorMessage(err.error.message);
              return;
            }
            if (err.message) {
              GeneralUtil.errorMessage(err.message);
              return;
            }
          }
          );
      }
    });
  }

  onGo(url) {
    //
    this.onLeaveCollapsible();
    this.onLeaveDdm();
    this._router.navigate(['/' + url]).then();
  }

  onToggleDdm() {
    //
    document.getElementById('user-ddm').classList.toggle('in');
  }

  onLeaveDdm() {
    //
    document.getElementById('user-ddm').classList.remove('in');
  }

  onToggleCollapsible() {
    //
    document.getElementById('collapsibleM').classList.toggle('in');
  }

  onLeaveCollapsible() {
    //
    document.getElementById('collapsibleM').classList.remove('in');
  }

  onLogIn() {
    //
    this.onLeaveDdm();
    openWindowWithoutEvent('w-login');
  }

  onUnder(){
    this.onLeaveCollapsible();
    under(event);
  }

}
