import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NauticalService} from '../../services/nautical.service';
import {NauticalModel, NauticalReservationModel} from '../../models/nautical.model';
import {Router} from '@angular/router';

declare function closeWindow(event: any, wID: any);

@Component({
  selector: 'app-w-tour-info',
  templateUrl: './w-tour-info.component.html',
  styleUrls: ['./w-tour-info.component.css']
})
export class WTourInfoComponent implements OnInit {

  subscriptionNautical: Subscription;
  nauticalOffer: NauticalModel;

  constructor(
    private _router: Router,
    private _nautService: NauticalService) {
  }

  ngOnInit() {
    this.subscriptionNautical = new Subscription();
    this.nauticalOffer = new NauticalModel();
    this.listenNauticalOffer();
  }

  listenNauticalOffer() {
    this.subscriptionNautical.add(this._nautService._fireNauticalOffer.subscribe(
      (data) => {
        this.nauticalOffer = data;
      }
    ));
  }

  onSelectThis(event) {
    event.stopPropagation();
    //
    alert('selected');
  }

  onCloseWindow(event, wID) {
    event.stopPropagation();
    //
    closeWindow(event, wID);
  }

  onDoBook(event, wID) {
    event.stopPropagation();
    //
    closeWindow(event, wID);
    const reservation = new NauticalReservationModel();
    reservation.nautical_package = this.nauticalOffer;
    this._nautService.setReservation(reservation);
    this._router.navigate(['/nautica.booking']).then();
  }
}
